import React, { useEffect, useState, useReducer } from 'react'
import { AddWatchList, GetExchangeRateTrade, GetRapidExchangeRateTrade,  GetOpenTradeListByUserId, RemoveUserTrade, closeOpenTrade, deleteTrade, getClosetradeList, getOpentradeList, getWatchList, gettradeList, removeWatchList, updateOpenTrade } from '../../services/TradeService'
import moment from 'moment/moment'
import { Link } from 'react-router-dom'
import EditTrade from '../Admin/Model/EditTrade'
import { getProviders } from '../../services/ProviderServices'
import { getPortfoloio } from '../../services/PortfolioService'
import DeleteConfirmation from '../commonComponent/DeleteConfirmation'
import { MdOutlineCreateNewFolder } from 'react-icons/md';
import { getSearchStockDetails } from '../../services/HomeService'
import { IoMdArrowRoundUp, IoMdArrowRoundDown } from 'react-icons/io'
import {IoEyeSharp ,IoReaderOutline ,IoTimeOutline} from 'react-icons/io5'
import { AiOutlineCalculator } from 'react-icons/ai'
import Pagination from '../commonComponent/Pagination'
import Loader from '../commonComponent/Loader'
import axios from 'axios';
import process from "process";
import ViewImage from '../commonComponent/commonModels/ViewImage'
import CountryCurrencyList from '../commonComponent/CountryCurrencyList'

// import { getExchangeRateAPI } from '../../services/commonServices'
import { GetExchangeRate, GetRapidExchangeRate } from '../../services/TradeService'
import { toast } from 'react-toastify'
import { BsInfoCircleFill } from 'react-icons/bs'
import OrderHistory from './Model/OrderHistory'
import BuySellUpdateTrade from './Model/BuySellUpdateTrade'
import RemoveTrade from '../commonComponent/RemoveTrade'
import CloseTrade from '../commonComponent/CloseTrade'
const API_URL = process.env.REACT_APP_API_BASE_URL

const initialopenTradeList = []
const reducer = (state, action) => {
	switch (action.type) {
		case "COMPLETE":
			return action.data.map((todo) => {
				// todo.sort((date1, date2) => date2.openDate - date1.openDate);
				return todo;
			});
		default:
			return state;
	}
};

const OpenTradeShowTable = () => {
	return (
		''
	)
}
const CustomTooltip = ({ content, position }) => (
	<div className="custom-tooltip" style={{ top: position.top, left: position.left }}>
		<div className="tooltip-content">
			<h6>Actions</h6>
			{content}
		</div>
	</div>
);
const TradeTable = ({ countryList, strategyList, portfololioList, setTradeCode, setOpenTradeRun, runAgainDashBoard, setCalculatedData, userType, setCalculatedTradeData }) => {
	const [loading, setLoading] = useState(false)
	const [tradeList, setTradeList] = useState([])
	const [watchList, setWatchList] = useState([])
	const [watchListRun, setWatchListRun] = useState(true)
	const [tradeListRun, setTradeListRun] = useState(true)
	const [deleteConfirmData, setDeleteConfirmData] = useState('')
	const [openTradeLoding, setOpenTradeLoding] = useState(false)
	const [openTradeClone, setOpenTradeClone] = useState([])
	const [countryShow, setCountryShow] = useState('')
	const [profitLossList, setProfitLossList] = useState([])
	const[ConfirmRemoveData , setConfirmRemoveData] =useState('')

	const [type, setType] = useState('')
	const [pagination, setPagination] = useState({ currentPage: 1, pageNumber: 1, pageSize: 15, totalPages: 0 })
	const { pageNumber, pageSize, currentPage, totalPages } = pagination
	const [paginationClose, setPaginationClose] = useState({ currentPageClose: 1, pageNumberClose: 1, pageSizeClose: 15, totalPagesClose: 0 })
	const { pageNumberClose, pageSizeClose, currentPageClose, totalPagesClose } = paginationClose
	const [paginationTrade, setPaginationTrade] = useState({ currentPageTrade: 1, pageNumberTrade: 1, pageSizeTrade: 15, totalPagesTrade: 0 })
	const { pageNumberTrade, pageSizeTrade, currentPageTrade, totalPagesTrade } = paginationTrade
	const [paginationWatch, setPaginationWatch] = useState({ currentPageWatch: 1, pageNumberWatch: 1, pageSizeWatch: 15, totalPagesWatch: 0 })
	const { pageNumberWatch, pageSizeWatch, currentPageWatch, totalPagesWatch } = paginationWatch
	const [typeShow, setTypeShow] = useState('pendingTrade')
	const [typeShowTrade, setTypeShowTrade] = useState('TRADES')
	const [deleteType, setDeleteType] = useState('')
	const [closeTradeList, setCloseTradeList] = useState([])
	const [closetradeListRun, setClosetradeListRun] = useState(false)
	const [chartImg, setChartImg] = useState('')
	const [portFolipID, setPortFolioID] = useState()
	const [stopLossErr, setStopLossErr] = useState(false)
	const [EditOpenTrade, setEditOpenTrade] = useState(false)
	const [stockDetails, setStockDetails] = useState({})
	const [tooltips, setTooltips] = useState({});
	const [transactionId, setTransactionId] = useState(null);
	const [shouldRefreshData, setShouldRefreshData] = useState(false);
	const [buySellTradeData, setBuySellTradeData] = useState({})
	const [editedDate, setEditedDate] = useState('');
	const [editedEntryPrice, setEditedEntryPrice] = useState('');
	let selectedPortfolio = localStorage.getItem('portFolioId')
	const [showModal, setShowModal] = useState(false);


	const handleUpdateStock = (value) => {

		if (!EditOpenTrade) {
			setStockDetails(value)
			setEditOpenTrade(true)
			setStopLossErr(false)
			setEditedDate(moment(value.openDate).format('YYYY-MM-DD'));
			setEditedEntryPrice(value.entryPrice);
		}
	}

	const handleDateChange=(e)=>{
		if (e.target.name === 'editedDate') {
			setEditedDate(e.target.value);
		} else if (e.target.name === 'editedEntryPrice') {
			setEditedEntryPrice(e.target.value);
		}
	}
	const handleChangeStockInput = (e) => {
		// debugger
		if ((Number(e.target.value) >= 0 || e.target.value == '.') && e.target.value != '') {
			var roundedNumber = e.target.value;
			var decimalIndex = roundedNumber.indexOf(".");
			if (decimalIndex !== -1) {
				var decimalPlaces = roundedNumber.length - decimalIndex - 1;
				if (decimalPlaces > 4) {
					roundedNumber = roundedNumber.slice(0, decimalIndex + 5);
				}
			}
			let n = e.target.value.split('.')
			// if (stockDetails?.short == 'buy') {
			// 	if (stockDetails?.entryPrice <= Number(e.target.value)) {
			// 		toast.error('Set lower stop loss price than entry while buy trading')
			// 	} else {
			// 		setStopLossErr(false)
			// 		if (n.length == 2) {
			// 			if (n[0].length <= 10) {
			// 				setStockDetails({ ...stockDetails, [e.target.name]: roundedNumber })
			// 			}
			// 		} else if (e.target.value.length <= 10) {

			// 			setStockDetails({ ...stockDetails, [e.target.name]: roundedNumber })
			// 		}

			// 	}
			// } else {
			if (n.length == 2) {
				if (n[0].length <= 10) {
					setStockDetails({ ...stockDetails, [e.target.name]: roundedNumber })
				}
			} else if (e.target.value.length <= 10) {

				setStockDetails({ ...stockDetails, [e.target.name]: roundedNumber })
			}
			setStopLossErr(false)
			// }


		}

		

		else {
			setStopLossErr(true)
			setStockDetails({ ...stockDetails, [e.target.name]: e.target.value })
		}
	}
	const handleChangeOpenTradeBlurInput = (e) => {
		// debugger
		// if (stockDetails?.short == 'sell') {
		// 	if (stockDetails?.entryPrice >= Number(e.target.value)) {
		// 		setStopLossErr(true)
		// 		toast.error('Set higher stoploss price than entry while sell trading')
		// 	}
		// }
	}
	const handleSaveUpdate = () => {

		if (stockDetails.stopLossPrice >= 0 && !stopLossErr &&
			editedDate !== '' &&
			editedEntryPrice !== '') {
			try {

				const parseentryPrice = parseFloat(((editedEntryPrice)))
				const updatedStockDetails = {
					...stockDetails,
					openDate: moment(editedDate).format("YYYY-MM-DD"),
					// openDate: moment(editedDate).toDate(),
					// entryPrice: parseFloat(((editedEntryPrice))),
					entryPrice: (parseentryPrice).toString()
				};


				updateOpenTrade(updatedStockDetails).then((res) => {
					if (res?.status === 200) {
						setStockDetails({})
						setEditOpenTrade(false)
						handleGetOpenTradelist(typeShow)
					}
				})
			} catch (error) {

			}
		}
	}
	useEffect(() => {
		setPortFolioID(selectedPortfolio)
	}, [selectedPortfolio])

	const handlePageChange = (newPage) => {
		setPagination({ ...pagination, currentPage: currentPage + newPage })
		// Perform data fetching or other actions when page changes
	};
	const handlePageChangeClose = (newPage) => {
		setPaginationClose({ ...paginationClose, currentPageClose: currentPageClose + newPage })
		// Perform data fetching or other actions when page changes
	};
	const handlePageChangeTrade = (newPage) => {
		setPaginationTrade({ ...paginationTrade, currentPageTrade: currentPageTrade + newPage })
		// Perform data fetching or other actions when page changes
	};
	const handlePageChangeWatch = (newPage) => {
		setPaginationWatch({ ...paginationWatch, currentPageWatch: currentPageWatch + newPage })
		// Perform data fetching or other actions when page changes
	};

	const [openTradeList, dispatch] = useReducer(reducer, initialopenTradeList);

	const handleGetAdminTradeList = (typeShowCheck) => {
debugger
		let data = {
			id: userType?.userId,
			pageNumber: currentPage,
			pageSize: pageSize,
			search: ''
		}
		if (data.id !== undefined && typeShowCheck == 'pendingTrade') {

			try {
				gettradeList(data).then((res) => {
					if (res?.status === 200) {

						setTradeList(res.data.data)
						setPaginationTrade({ ...paginationTrade, totalPagesTrade: res.data.totalPages })

					}
				})
			} catch (error) {

			}
		}
	}

	// useEffect(() => {
	// 	if (typeShow == 'pendingTrade') {
	// 		handleGetAdminTradeList('pendingTrade')
	// 	} else {
	// 		handleGetOpenTradelist(typeShow)
	// 	}


	// }, [tradeListRun === true, runAgainDashBoard, userType, typeShowTrade, portFolipID])


	useEffect(() => {
		debugger
		if (typeShow === 'pendingTrade') {
			handleGetAdminTradeList('pendingTrade');
		} else {
			handleGetOpenTradelist(typeShow);
		}
	}, [tradeListRun === true, runAgainDashBoard, userType, typeShowTrade, portFolipID]);

	// close trade list API

	const handleGetCloseTradeList = () => {
		let data = {
			id: userType?.userId,
			pageNumber: currentPageClose,
			pageSize: pageSizeClose
		}
		if (data.id !== undefined) {
			try {
				getClosetradeList(data).then((res) => {

					if (res?.status === 200) {

						setClosetradeListRun(false)
						let data = res.data.data
						for (let i = 0; i < data.length; i++) {
							if (data[i].short == "sell") {
								let n = data[i].valueInShares - data[i].closeAmount
								data[i].profitLoss = n
							} else {
								let n = data[i].closeAmount - data[i].valueInShares
								data[i].profitLoss = n
							}
							setCloseTradeList(data)
						}
						setPaginationClose({ ...paginationClose, totalPagesClose: res.data.totalPages })
						if (countryShow) {
						}
					}
				})
			} catch (error) {

			}
		}
	}


	// OPEN TRADE LIST API
	const handleGetOpenTradelist = (types) => {
		debugger
		let data = {
			id: userType?.userId,
			pageNumber: currentPage,
			pageSize: pageSize,
			type: typeShowTrade,
			portfolioId: portFolipID
		}
		if (data.id !== undefined && types == 'openTrade') {
			try {
				getOpentradeList(data).then((res) => {

					if (res?.status === 200) {
						setPagination({ ...pagination, totalPages: res.data.totalPages })

						setTradeListRun(false)
						setOpenTradeRun(false)
						setOpenTradeClone(res.data.data)
						addProfitLoass(res.data.data)

					}
				})
			} catch (error) {
				setTradeListRun(false)
				setOpenTradeRun(false)
			}
		}
	}

	const twoDecimal = (value) => {

		var roundedNumber = value.toString(); // Convert value to string
		var decimalIndex = roundedNumber.indexOf(".");

		if (decimalIndex !== -1) {
			var decimalPlaces = roundedNumber.length - decimalIndex - 1;

			if (decimalPlaces > 4) {
				// Remove trailing zeros after the decimal point
				roundedNumber = parseFloat(roundedNumber).toString();
			}
		}

		return roundedNumber;
	}
	const [stockcurrencycode, setStockCurrencycode] = useState('')
	const [portfoliocurrencycode, setPortfoliocurrencycode] = useState('')
	const addProfitLoass = (tradeListData) => {
		debugger

		let n = 0
		let pdata = []
		if (tradeListData != undefined && tradeListData.length > 0) {
			for (let i = 0; i < tradeListData.length; i++) {
				if (tradeListData[i].short !== '') {
					n = n + 1
					// pdata.push({...tradeListData[i]})
					// dispatch({ type: "COMPLETE", data: pdata });
					// let stockCountryCode = countryList.filter((val) => val.key.toUpperCase() == tradeListData[i]?.country.toUpperCase())

					// let stockCodeArr = tradeListData[i].stockCode.split('_')

					// let symbol = CountryCurrencyList.filter((val) => val.code == stockCountryCode[0]?.value)
					let stockPortCountry = portfololioList.filter((val) => val.portfolioId == tradeListData[i].portfolioId)
					setPortfoliocurrencycode(stockPortCountry[0]?.currencySymbol)
					let stockCurrency = tradeListData[i].stockCode.split('/')
					// console.log(CountryCurrencyList ,"CountryCurrencyList")
					let symboleData = CountryCurrencyList.filter((value) => value.code == stockCurrency[1])
					setStockCurrencycode(symboleData[0]?.symbol_native)

					try {
						// GetExchangeRateTrade(tradeListData[i].stockCode).then((respons) => {
							GetRapidExchangeRateTrade(tradeListData[i].stockCode).then((respons) => {
							let eschangeParam = {
								CurrencyFrom: stockCurrency[1],
								CurrencyTo: stockPortCountry[0]?.currency
							}
							try {
								// GetExchangeRate(eschangeParam).then((res) => {
									GetRapidExchangeRate(eschangeParam).then((res) => {

									const exPrice = res?.data?.rates?.[eschangeParam?.CurrencyTo];
									const bidPrice =
                    respons?.data?.rates?.[
                      tradeListData[i].stockCode.split("/")[1]
                    ];
									const askPrice =
                    respons?.data?.rates?.[
                      tradeListData[i].stockCode.split("/")[1]
                    ];

									if (exPrice != undefined && bidPrice != undefined) {
										if (tradeListData[i].short == 'sell') {

											let openTradeListing = tradeListData[i]

											let plValue = (tradeListData[i]?.valueInShares - ((bidPrice * exPrice) * tradeListData[i]?.quantity || 0))
											openTradeListing.profitLoss = Number(plValue).toFixed(3)
											openTradeListing.stockCountrySymbol = symboleData[0]?.symbol_native || stockCurrency[1]
											openTradeListing.portfoliocurrencycode = stockPortCountry[0]?.currencySymbol
											openTradeListing.stockName = tradeListData[i]?.stockCode
											openTradeListing.cPrice = twoDecimal(bidPrice)
											openTradeListing.entryPrice = twoDecimal(tradeListData[i].entryPrice)
											pdata.push({ ...openTradeListing })
										} else if (tradeListData[i].short == 'buy' || tradeListData[i].short == 'long') {
											let openTradeListing = tradeListData[i]
											let plValue = (((askPrice * exPrice) * tradeListData[i]?.quantity || 0) - tradeListData[i]?.valueInShares)
											openTradeListing.profitLoss = Number(plValue).toFixed(3)
											openTradeListing.stockCountrySymbol = symboleData[0]?.symbol_native ||stockCurrency[1]
											openTradeListing.portfoliocurrencycode = stockPortCountry[0]?.currencySymbol
											openTradeListing.stockName = tradeListData[i].stockCode
											openTradeListing.cPrice = twoDecimal(askPrice)
											openTradeListing.entryPrice = twoDecimal(tradeListData[i].entryPrice)
											pdata.push({ ...openTradeListing })
										}
									} else {
										let openTradeListing = tradeListData[i]

										openTradeListing.profitLoss = 'NA'
										openTradeListing.stockCountrySymbol = stockPortCountry[0]?.currencySymbol
										openTradeListing.stockName = tradeListData[i].stockCode
										openTradeListing.cPrice = 'NA'
										pdata.push({ ...openTradeListing })
									}
									pdata.sort((date1, date2) => date2.transactionNo - date1.transactionNo);
									dispatch({ type: "COMPLETE", data: pdata });
								})
							} catch (error) {
								dispatch({ type: "COMPLETE", data: tradeListData[i] });
							}


						})
					} catch (error) {
						dispatch({ type: "COMPLETE", data: tradeListData[i] });
					}


				} else {
					let openTradeListing = tradeListData[i]

					openTradeListing.profitLoss = 'NA'
					openTradeListing.stockCountrySymbol = 'NA'
					openTradeListing.stockName = tradeListData[i].stockCode
					openTradeListing.cPrice = 'NA'
					pdata.push({ ...openTradeListing })
					pdata.sort((date1, date2) => date2.transactionNo - date1.transactionNo);
					dispatch({ type: "COMPLETE", data: pdata });
				}


			}
		} else {
			dispatch({ type: "COMPLETE", data: [] });
		}
		setOpenTradeLoding(false)
		// setOpen

	}

	const handleDeleteTrade = (data) => {
// console.log(deleteConfirmData ,"deleteConfirmData")
// console.log(typeof(data.ClosePrice) ,typeof(data.CloseWithMyPrice),typeof(data.TradeId) ,"data")
		try {
			closeOpenTrade(data).then((res) => {
				if (res?.status === 200) {
					setTradeListRun(true)
					setProfitLossList([])
					setDeleteConfirmData('')
					handleGetOpenTradelist(typeShow)
				}
			})
		} catch (error) {

		}

	}
	const handleRemoveWatchListConfirm = () => {
		try {
			removeWatchList(deleteConfirmData).then((res) => {
				if (res?.status === 200) {
					setWatchListRun(true)
				}
			})
		} catch (error) {
		}
	}
	const deleteConfirm = () => {
		if (deleteType == 'openTrade') {

			handleDeleteTrade()
		} else if (deleteType == 'watchList') {
			handleRemoveWatchListConfirm()
		}
	}

	useEffect(() => {
		let data = {
			id: userType?.userId,
			pageNumber: currentPageWatch,
			pageSize: pageSizeWatch
		}
		if (data.id !== undefined) {
			try {
				getWatchList(data).then((res) => {
					setWatchListRun(false)
					if (res?.status === 200) {
						setWatchList(res.data.data)
						setPaginationWatch({ ...paginationWatch, totalPagesWatch: res.data.totalPages })
					}
				})
			} catch (error) {

			}
		}


	}, [userType?.userId, currentPageWatch, watchListRun])
	const handleAddWatchList = (value) => {
		// console.log(value ,"value")
		let data = {
			userId: userType?.userId,
			country: value.country,
			providerName: value.providerName,
			stockId: value.stockId,
			stockCode: value.stockCode,
			stockName: value.stockCode
		}

		setLoading(true);

		try {
			AddWatchList(data).then((res) => {
				if (res?.status === 200) {
					setWatchListRun(true)
				}
			}).finally(() => {
				setLoading(false);
			});
		} catch (error) {
			setLoading(false);
		}
	}

	const handleRemoveWatchList = (id) => {
		setDeleteConfirmData(id)
		setDeleteType('watchList')
		setType('Remove This WatchList')
	}

	const handleAddOpenTradeData = (value) => {
		setOpenTradeRun(true)
		setCalculatedData({
			portFolioId: value.portfolioId,
			country: value.country,
			providerId: value.providerID,
			tradeId: value.tradeId,
			type: value?.type || 'TRADES',
			margin: value.margin,
			entry: value.entryPrice,
			stockCode: value.stockCode,
			stockName: value.stockName,
			quantity: value.price / value.entry,
			currentPrice: value.price,
			short: value.short,
			share: '',
			stopLoss: value.stopLoss,
			cfd: '',
			target: '',
			cummission: '',
			reward: '',
			rewardPercent: '',
			providerName: value.providerName,
			tradingSystemId: value.tradingSystemId,
			ask_price: value.ask_price,
			ask_size: value.ask_size,
			bid_price: value.bid_price,
			bid_size: value.bid_size
		})
	}
	const handleAddCalculatorData = (value) => {
		setOpenTradeRun(true)
		setCalculatedTradeData({
			portFolioId: value.portfolioId,
			type: value?.type || 'TRADES',
			tradeId: value.tradeId,
			country: value.country,
			tradingSystemId: value?.tradingSystemId,
			providerId: value.providerID,
			providerName: value.providerName,
			margin: value.margin,
			entry: value.entryPrice,
			stockCode: value.stockCode,
			stockName: value.stockName,
			quantity: value.price / value.entry,
			currentPrice: value.price,
			long: value.short,
			target: value?.target,
			ask_price: value.ask_price,
			ask_size: value.ask_size,
			bid_price: value.bid_price,
			bid_size: value.bid_size,
			share: '',
			stopLoss: value.stopLoss,
			cfd: '',
			cummission: '',
			reward: '',
			rewardPercent: ''
		})
	}
	// useEffect(() => {

	// }, [typeShow])
	const handleChangeTypeOfShow = (value) => {
		if (value == 'pendingTrade') {
			handleGetAdminTradeList(value)
		} else if (value == 'openTrade') {
			setOpenTradeLoding(true)
			handleGetOpenTradelist(value)
		} else if (value == 'closeTrade') {
			handleGetCloseTradeList()
		} else if (value == 'watchList') {
		}
		setStopLossErr(false)
		setEditOpenTrade(false)
		setTypeShow(value)
	}
	const [nulldata, setNulldata] = useState(1)
	useEffect(() => {
		setNulldata(nulldata + 1)
	}, [profitLossList.length !== openTradeList.length, openTradeList, profitLossList])

	const handleMouseEnter = (id, event) => {
		setTooltips({ ...tooltips, [id]: { top: event.clientY, left: event.clientX } });
	};

	const handleMouseLeave = () => {
		setTooltips({});
	};
	const CustomTooltip = ({ content, position }) => (
		<div className="custom-tooltip" style={{ top: position.top, left: position.left }}>
			<div className="tooltip-content">
				<h6>Actions</h6>
				{content}
			</div>
		</div>
	);
	const handleOrderHistory = (id) => {
		setTransactionId(id);

	};

	const handleBuySellEdit = (value) => {

		setBuySellTradeData(value)
	}

	const handleDataUpdate = () => {
		setShouldRefreshData(true);
	};


	function getWatchListId(stockCode) {
		debugger
		const watchListItem = watchList.find(item => item.stockCode.includes(stockCode));
		return watchListItem ? watchListItem.watchListId : null;
	}

	const deleteConfirmRemove=(data)=>{
		// debugger
		if (deleteType == 'pendingTrade') {
			handleRemoveTrade(data);

		} 
	}
	const handleRemovePendingTrade = (val) => {
		debugger
		
		setConfirmRemoveData(val)
				setDeleteType('pendingTrade')
		        setType('Remove This Pending Trade')
	  };
	 
	const handleRemoveTrade =async (data) => {
		debugger
				try {

					await RemoveUserTrade(data?.tradeId , userType?.userId).then((res) => {
						if (res?.status === 200) {
							// setTypeShow('pendingTrade')
							 handleChangeTypeOfShow('pendingTrade')
							//handleGetOpenTradelist('pendingTrade')
							setConfirmRemoveData('')
							setProfitLossList([])
							setDeleteConfirmData('')
							
						}
					})
				} catch (error) {
		
				}
		
			
		}
		const handleCloseTrade = (valtransaction , valstopprice ) => {
			// console.log(valtransaction ,valstopprice ,"valtransaction ,valstopprice")
			// debugger
			setShowModal(true)
			setDeleteConfirmData({ transactionNo: valtransaction, stopLossPrice: valstopprice  })
			setType('Close This Open Trade')
			setDeleteType('openTrade')
			
			// handleSaveAndClose(val)
	
		}
		const deleteConfirmClose = (data) => {
			console.log(data ,"data")
			if (deleteType == 'openTrade') {
	
				handleDeleteTrade(data);
			} else if (deleteType == 'watchList') {
				handleRemoveWatchListConfirm()
			}
	
		}
		// const handleCloseTrade = (val) => {
		// 	setDeleteConfirmData(val)
		// 	setType('Close This Open Trade')
		// 	setDeleteType('openTrade')
		// }
	return (
		<>
			<div class="row">
				<section>
					<div class="container-fluid">
						<div class="row">
							<div class="col-sm-12">
								<div class="postion-bx-min mt-4">


									<div class="postion-frm mt-1 table-input1" style={{ "border-top": "1px solid #cbd0dd" }}>
										{/* <span class="padding-ds">Pending Positions</span>
                                        <span class="padding-ds">Open Positions</span>
                                        <span class="padding-ds">Watchlist</span> */}
										<div class="col-sm-12 tabs-sec">
											<ul class="nav nav-pills nav-justified" id="pills-tab" role="tablist">
												<li onClick={() => handleChangeTypeOfShow('pendingTrade')} ><button class="nav-link  color-thumb active" id="pills-profile-tab" data-bs-toggle="pill"
													data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
													aria-selected="false"><span >Pending Positions</span></button></li>

												<li onClick={() => handleChangeTypeOfShow('openTrade')}><button class="nav-link  color-thumb" id="pills-profile-tab" data-bs-toggle="pill"
													data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
													aria-selected="false"><span >Open Positions</span></button></li>

												<li onClick={() => handleChangeTypeOfShow('closeTrade')}><button class="nav-link color-thumb " id="closed-pills-tab" data-bs-toggle="pill"
													data-bs-target="#closed-pills" type="button" role="tab" aria-controls="closed-pills"
													aria-selected="false"><span >Closed Positions</span></button></li>

												<li onClick={() => handleChangeTypeOfShow('watchList')}><button class="nav-link color-thumb" id="pills-contact-tab" data-bs-toggle="pill"
													data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact"
													aria-selected="false"><span >Watchlist</span></button></li>
												{
													typeShow == 'openTrade' &&
													<li className='ms-auto'>
														<select className="form-select  mb-4" value={portFolipID} aria-label="form-select-lg example" onChange={(e) => setPortFolioID(Number(e.target.value))}>

															{
																portfololioList?.map((val, ind) => {
																	return (
																		<option key={ind} value={val.portfolioId}>{val.portfolioName} </option>
																	)
																})
															}
														</select>
													</li>
												}
											</ul>
											<div class="tab-content" id="pills-tabContent">
												<div class="tab-pane fade show active" id="pills-home" role="tabpanel"
													aria-labelledby="pills-home-tab">
													<div class="table-responsive text-center">
														<table class="table table-bordered  text-center">
															<thead>
																<tr>
																	<th scope="col" className='color-grey'>Date</th>
																	<th scope="col" className='color-grey'>Code</th>
																	{/* <th scope="col" className='color-grey'>Strategy</th> */}
																	<th scope="col" className='color-grey'>Direction</th>
																	<th scope="col" className='color-grey'>Entry</th>
																	<th scope="col" className='color-grey'>Target</th>
																	<th scope="col" className='color-grey'>Stop</th>
																	{/* <th scope="col" className='color-grey'>Strategy</th> */}
																	<th scope="col" className='color-grey'>Actions</th>
																	<th scope="col" className='color-grey'>Chart</th>
																	{/* <th scope="col" className='color-grey'>Closed</th> */}
																	<th scope="col" className='color-grey'>Calculate</th>
																	<th scope="col" className='color-grey'>Trade</th>
																	{/* <th scope="col" className='color-grey'>Edit</th>
																	<th scope="col" className='color-grey'>Delete</th> */}
																	<th scope="col" className='color-grey'>View</th>
																	<th scope="col" className='color-grey'>Watchlist</th>
																	<th scope="col" className='color-grey'>Delete</th>

															
																</tr>
															</thead>
															<tbody>
																{
																	tradeList.map((val, ind) => {
																		return (
																			<tr key={ind}>
																				<th scope="row">{moment(val.tradeDate).format('DD MMM YYYY')}</th>
																				<td>{val.stockCode}</td>
																				<td>{val.short}</td>
																				<td>$ {Number(val?.entryPrice)}</td>
																				<td>$ {Number(val?.target)}</td>
																				<td>$ {Number(val?.stopLoss)}</td>
																				{/* <td>{val?.tradingSystems?.name || ''}</td> */}
																				<td>{val.action}</td>
																				<td><button class="btn padding-btn-dtd open-btn w-100" data-bs-toggle="modal" title='View Image' data-bs-target="#viewImage" onClick={() => setChartImg(val.chartFile)}> <img src="assets/image/folder.png" class="img-fluid" alt='' />  </button> </td>
																				<td><button class="btn padding-btn-dtd open-trade" onClick={() => handleAddCalculatorData(val)} title='Calculate This' data-bs-toggle="modal" data-bs-target="#calculator"> <AiOutlineCalculator /> </button> </td>
																				<td><button class="btn padding-btn-dtd open-trade" onClick={() => handleAddOpenTradeData(val)} title='Trade This' data-bs-toggle="modal" data-bs-target="#openTrade"> <MdOutlineCreateNewFolder /> </button> </td>
																				<td><button class="btn padding-btn-dtd open-btn w-100" data-bs-toggle="modal" title='View Chart' data-bs-target="#StockView" onClick={() => setTradeCode(val.stockCode)}> <img src="assets/image/folder.png" class="img-fluid" alt='' />  </button> </td>
																				{/* {
																					watchList.length > 0 ? watchList.some(value => val.stockCode.includes(value.stockCode)) === true ? <td><button class="btn padding-btn-dtd watch w-100" title='WatchList'> <img src="assets/image/watchlist.png" class="img-fluid" alt='' /></button> </td> :
																						<td><button class="btn padding-btn-dtd watch w-100" title='Add to WatchList' onClick={() => handleAddWatchList(val)}> <img src="assets/image/watch.png" class="img-fluid" alt='' /></button> </td>
																						: <td><button class="btn padding-btn-dtd watch w-100" title='Add to WatchList' onClick={() => handleAddWatchList(val)}> <img src="assets/image/watch.png" class="img-fluid" alt='' /></button> </td>
																				} */}


																				{
																					watchList.length > 0 ?
																						watchList.some(value => val.stockCode.includes(value.stockCode)) === true ?
																							<td>

																								<button class="btn padding-btn-dtd watch w-100" data-bs-toggle="modal" data-bs-target="#deleteModel" title='WatchList' onClick={() => handleRemoveWatchList(getWatchListId(val.stockCode))}>
																									<img src="assets/image/watchlist.png" class="img-fluid" alt='' />
																								</button>
																							</td>
																							:
																							<td>

																								<button class="btn padding-btn-dtd watch w-100" title='Add to WatchList' onClick={() => handleAddWatchList(val)} disabled={loading}>
																									<img src="assets/image/watch.png" class="img-fluid" alt='' />
																								</button>
																							</td>
																						:
																						<td>
																							<button class="btn padding-btn-dtd watch w-100" title='Add to WatchList' onClick={() => handleAddWatchList(val)} disabled={loading}>
																								<img src="assets/image/watch.png" class="img-fluid" alt='' />
																							</button>
																						</td>
																				}
																				<td><button class="btn padding-btn-dtd delete-btn w-100 delete-admin" title='Delete' data-bs-toggle="modal" data-bs-target="#RemoveModel" onClick={() => handleRemovePendingTrade(val)}>  <i class="bi bi-trash3-fill"></i>  </button> </td>

																			</tr>
																		)
																	})
																}
															</tbody>
														</table>
														<Pagination
															currentPage={currentPageTrade}
															totalPages={totalPagesTrade}
															onPageChange={handlePageChangeTrade}
															setPagination={setPaginationTrade}
															pageNumber={pageNumberTrade}
															pageSize={pageSizeTrade}
															pagination={paginationTrade}
														/>
													</div>
												</div>
												<div class="tab-pane fade " id="pills-profile" role="tabpanel"
													aria-labelledby="pills-profile-tab">
													<div class="table-responsive text-center">
														<table class="table table-bordered text-center">
															<thead>
																<tr>
																	<th scope="col" className='color-grey'>Date</th>
																	<th scope="col" className='color-grey'>Code</th>
																	<th scope="col" className='color-grey'>Direction</th>
																	{/* <th scope="col" className='color-grey'>Strategy</th> */}
																	<th scope="col" className='color-grey'>Entry</th>
																	<th scope="col" className='color-grey'>Current Price</th>
																	<th scope="col" className='color-grey'>Trade Amount</th>
																	<th scope="col" className='color-grey'>Quantity</th>
																	<th scope="col" className='color-grey'>Stop</th>
																	<th scope="col" className='color-grey'>Profit/Loss</th>
																	<th scope="col" className='color-grey'>Edit</th>
																	<th scope="col" className='color-grey'>Buy/Sell</th>
																	<th scope="col" className='color-grey'>Close</th>
																	{/* <th scope="col" className='color-grey'><button class="btn padding-btn-dtd open-btnview w-100" title='View Chart'> <img src="assets/image/folder.png" class="img-fluid" alt='' />  </button>  </th> */}
																	<th scope="col" className='color-grey'> <IoEyeSharp title='View' size={18}/>   </th>
																	<th scope="col" className='color-grey'> <img src="assets/image/watch.png" class="img-fluid size" alt=''  title='WatchList'/> </th>
																	<th scope="col" className='color-grey'> <img src="assets/image/orderhistory.png" class="img-fluid size" alt=''  title='Order History'/> </th>
																</tr>
															</thead>
															<tbody>
																{/* {console.log(openTradeLoding, openTradeClone.length, openTradeList.length, "openTradeLoding")} */}
																{/* (!openTradeLoding && openTradeClone.length) && */}

																{

																	(!openTradeLoding ) &&
																	openTradeList.map((val, ind) => {
																	{/* console.log(val , "val")  */}
																		let n = val.quantity.split('.')
																		{/* let m = strategyList.filter((value) => value.tradingSystemId == val.strategy) */ }
																		let m = 'dhb'
																		let currencysymbol = val.stockCode[1]

																		{/* console.log (currencysymbol,countryList , "currencysymbol ,countrylist") */ }
																		{/* let selectedStockCountry = countryList.filter((e) => e.key.toUpperCase() ==currencysymbol.toUpperCase()) */ }
																		{/* let symbol = CountryCurrencyList.filter((val) => val.code == selectedStockCountry[0]?.value)  */ }
																		let tradeSymbol = val?.portfolio?.currencySymbol

																		{/* console.log(tradeSymbol,"tradeSymbol") */ }
																		return (
																			<tr key={ind}>

																				<th>
																					{EditOpenTrade && stockDetails?.transactionNo === val.transactionNo ? (
																						<input
																							type="date"
																							className="know-commper-bx-min w-100"
																							name="editedDate"
																							value={editedDate}
																							onChange={handleDateChange}
																						/>
																					) : (
																						moment(val.openDate).format('DD-MM-YYYY')
																					)}
																				</th>
																				<td>{val.stockName} {val?.action?.length > 0 && <a className={'validation_msg '}

																					onMouseEnter={(e) => handleMouseEnter(val.transactionNo, e)}
																					onMouseLeave={handleMouseLeave}
																				><BsInfoCircleFill />
																					{val.name}
																					{tooltips[val.transactionNo] && (
																						<CustomTooltip
																							content={val.action}
																							position={tooltips[val.transactionNo]}
																						/>
																					)}
																				</a>}
																				</td>
																				<td>{val.short}</td>
																				{/* <td>{m[0]?.name || ''}</td> */}
																				<td>

																					{EditOpenTrade && stockDetails?.transactionNo === val.transactionNo ? (
																						<input
																							type="text"
																							className={EditOpenTrade && stopLossErr ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"}
																							name='editedEntryPrice'
																							value={editedEntryPrice}
																							onChange={handleDateChange}
																							onBlur={handleChangeOpenTradeBlurInput}
																						/>
																					) : (
																						`${val.stockCountrySymbol} ${parseFloat(val.entryPrice)}`
																					)}
																				</td>
																				{/* <td>{val.stockCountrySymbol} {parseFloat(val.entryPrice).toFixed(3)}</td> */}
																				<td>{val.stockCountrySymbol} {parseFloat(val?.cPrice).toFixed(3)}</td>
																				{/* <td>{val.portfoliocurrencycode} {twoDecimal((val.valueInShares).toString())}</td> */}
																				<td>{val.portfoliocurrencycode} {parseFloat(val.valueInShares).toFixed(2)}</td>
																				<td>{n.length > 1 ? Number(val.quantity).toFixed(3) : Number(val.quantity)}</td>




																				{
																					EditOpenTrade && stockDetails?.transactionNo == val.transactionNo ?
																						<td class="text-center"><input className={EditOpenTrade == true && stopLossErr ? "know-commper-bx-min input-err-color w-100" : "know-commper-bx-min w-100"} name='stopLossPrice' value={stockDetails.stopLossPrice} onChange={handleChangeStockInput} onBlur={handleChangeOpenTradeBlurInput} /></td>
																						:
																						<td>{val.stockCountrySymbol} {val.stopLossPrice}</td>
																				}
																				{/* <td>{val.target}</td> */}
																				{/* <td>test</td> */}
																				{/* <td>{val.isOpen ? "Open" : "Closed"}</td> */}
																				<td>{val.portfoliocurrencycode} {val?.profitLoss < 0 ? <>{val?.profitLoss} <span style={{ 'color': 'red', 'font-size': '14px;' }}><IoMdArrowRoundDown /></span> </> : <> {val.profitLoss} <span style={{ color: 'green', 'font-size': '14px;' }}><IoMdArrowRoundUp /></span></>}</td>
																				{
																					EditOpenTrade && stockDetails?.transactionNo == val.transactionNo ?
																						<td><button class="btn padding-btn-dtd edit-btn edit-btn-trade  w-100" title='Edit' onClick={() => handleSaveUpdate()}> Save </button> </td>
																						:
																						<td><button class="btn padding-btn-dtd edit-btn edit-btn-trade  w-100" title='Edit' onClick={() => handleUpdateStock(val)}> <img src="assets/image/edit.png" class="img-fluid" alt='' /> </button> </td>
																				}

																				<td><button class="btn padding-btn-dtd delete-btn w-100" style={{ padding: '0px' }} title='Close Trade' data-bs-toggle="modal" data-bs-target="#BuySellUpdateTrade" onClick={() => handleBuySellEdit(val)} > <img src="assets/image/buySell.png" style={{ 'max-width': '34px' }} class="img-fluid" alt='' /> </button> </td>
																				{/* <td><button class="btn padding-btn-dtd delete-btn w-100" title='Close Trade' data-bs-toggle="modal" data-bs-target="#deleteModel" onClick={() => handleCloseTrade(val.transactionNo)}> <img src="assets/image/delete.png" class="img-fluid" alt='' /> </button> </td> */}
																				<td><button class="btn padding-btn-dtd delete-btn w-100" title='Close Trade' data-bs-toggle="modal" data-bs-target="#closeTrade" onClick={() => handleCloseTrade(val.transactionNo , val.stopLossPrice)}> <img src="assets/image/delete.png" class="img-fluid" alt='' /> </button> </td>

																				<td><button class="btn padding-btn-dtd open-btn w-100" data-bs-toggle="modal" title='View Chart' data-bs-target="#StockView" onClick={() => setTradeCode(val.stockCode)}> <img src="assets/image/folder.png" class="img-fluid" alt='' />  </button> </td>
																				{/* {
																					watchList.length > 0 ? watchList.some(value => val.stockCode.includes(value.stockCode)) === true ? <td><button class="btn padding-btn-dtd watch w-100" title='WatchList'> <img src="assets/image/watchlist.png" class="img-fluid" alt='' /></button> </td> :
																						<td><button class="btn padding-btn-dtd watch w-100" title='Add to WatchList' onClick={() => handleAddWatchList(val)}> <img src="assets/image/watch.png" class="img-fluid" alt='' /></button> </td>
																						: <td><button class="btn padding-btn-dtd watch w-100" title='Add to WatchList' onClick={() => handleAddWatchList(val)}> <img src="assets/image/watch.png" class="img-fluid" alt='' /></button> </td>
																				} */}
																				{
																					watchList.length > 0 ?
																						watchList.some(value => val.stockCode.includes(value.stockCode)) === true ?
																							<td>

																								<button class="btn padding-btn-dtd watch w-100" data-bs-toggle="modal" data-bs-target="#deleteModel" title='WatchList' onClick={() => handleRemoveWatchList(getWatchListId(val.stockCode))}>
																									<img src="assets/image/watchlist.png" class="img-fluid" alt='' />
																								</button>
																							</td>
																							:
																							<td>

																								<button class="btn padding-btn-dtd watch w-100" title='Add to WatchList' onClick={() => handleAddWatchList(val)} disabled={loading}>
																									<img src="assets/image/watch.png" class="img-fluid" alt='' />
																								</button>
																							</td>
																						:
																						<td>
																							<button class="btn padding-btn-dtd watch w-100" title='Add to WatchList' onClick={() => handleAddWatchList(val)} disabled={loading}>
																								<img src="assets/image/watch.png" class="img-fluid" alt='' />
																							</button>
																						</td>

																				}
																				

																				
																				<td>
																				<button
																					className="btn padding-btn-dtd watch w-100"

																					data-bs-toggle="modal" title='View Chart' data-bs-target="#OrderHistory"
																					onClick={() => handleOrderHistory(val.transactionNo)}
																				>
																					<img src="assets/image/orderhistory.png" className="img-fluid" alt="" />
																				</button>
																				</td>
																				
																				{/* <Link to={'/chart'} state={{stockCode:val.stockCode}}></Link> */}
																			</tr>
																		)
																	})


																}
															</tbody>
														</table>
														{/* (!openTradeLoding && openTradeClone.length <=0) ? */}

														{
															(!openTradeLoding ) ?

																<Pagination
																	currentPage={currentPage}
																	totalPages={totalPages}
																	onPageChange={handlePageChange}
																	setPagination={setPagination}
																	pageNumber={pageNumber}
																	pageSize={pageSize}
																	pagination={pagination}
																/> :
																<div class="container-fluid">
																	<div class="container">
																		<div class="row">
																			<div class="col-md-12">
																				<div class="preloader1">
																					<div class="loader loader-inner-2">
																						<div class="loader loader-inner-3">
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>


														}

													</div>
												</div>
												<div class="tab-pane fade  " id="closed-pills" role="tabpanel"
													aria-labelledby="closed-pills-tab">
													<div class="table-responsive text-center">
														<table class="table table-bordered text-center">
															<thead>
																<tr>
																	<th scope="col " className='color-grey'>Date</th>
																	<th scope="col" className='color-grey'>Code</th>
																	<th scope="col" className='color-grey'>Direction</th>
																	<th scope="col" className='color-grey'>Entry</th>
																	<th scope="col" className='color-grey'>Stop</th>
																	<th scope="col" className='color-grey'>Trade Amount</th>
																	<th scope="col" className='color-grey'>Profit/Loss $</th>
																	<th scope="col" className='color-grey'>Profit/Loss %</th>
																	<th scope="col" className='color-grey'>Total Amount</th>
																	<th scope="col" className='color-grey'>View</th>
																	<th scope="col" className='color-grey'>Watchlist</th>
																</tr>
															</thead>
															<tbody>
																{
																	closeTradeList.map((val, ind) => {
																		return (
																			// countryShow.toUpperCase() === val.portfolio.country.toUpperCase()&&
																			<tr key={ind}>
																				<th scope="row">{moment(val.openDate).format('DD MMM YYYY')}</th>
																				<td>{val.stockName}</td>
																				<td>{val.short}</td>
																				<td>$ {val.entryPrice}</td>
																				<td>$ {Number(val.stopLossPrice).toFixed(2)}</td>
																				<td>$ {Number(val.valueInShares).toFixed(2)}</td>
																				<td>$ <span style={val?.profitLoss > 0 ? { 'color': 'green' } : { 'color': 'red' }}>{Number(val?.profitLoss).toFixed(2)} </span> </td>
																				<td><span style={val?.profitLoss > 0 ? { 'color': 'green' } : { 'color': 'red' }}>{Number(((val?.profitLoss) * 100) / val.valueInShares).toFixed(3)}%</span> </td>
																				<td>$ {Number(val.closeAmount)}</td>
																				<td><button class="btn padding-btn-dtd open-btn w-100" data-bs-toggle="modal" title='View Chart' data-bs-target="#StockView" onClick={() => setTradeCode(val.stockCode)}> <img src="assets/image/folder.png" class="img-fluid" alt='' />  </button> </td>
																				{/* {
																					watchList.length > 0 ? watchList.some(value => val.stockCode.includes(value.stockCode)) === true ? <td><button class="btn padding-btn-dtd watch w-100" title='WatchList'> <img src="assets/image/watchlist.png" class="img-fluid" alt='' /></button> </td> :
																						<td><button class="btn padding-btn-dtd watch w-100" title='Add to WatchList' onClick={() => handleAddWatchList(val)}> <img src="assets/image/watch.png" class="img-fluid" alt='' /></button> </td>


																						: <td><button class="btn padding-btn-dtd watch w-100" title='Add to WatchList' onClick={() => handleAddWatchList(val)}> <img src="assets/image/watch.png" class="img-fluid" alt='' /></button> </td>


																				} */}

																				{
																					watchList.length > 0 ?
																						watchList.some(value => val.stockCode.includes(value.stockCode)) === true ?
																							<td>

																								<button class="btn padding-btn-dtd watch w-100" data-bs-toggle="modal" data-bs-target="#deleteModel" title='WatchList' onClick={() => handleRemoveWatchList(getWatchListId(val.stockCode))}>
																									<img src="assets/image/watchlist.png" class="img-fluid" alt='' />
																								</button>
																							</td>
																							:
																							<td>
 
																								<button class="btn padding-btn-dtd watch w-100" title='Add to WatchList' onClick={() => handleAddWatchList(val)} disabled={loading}>
																									<img src="assets/image/watch.png" class="img-fluid" alt='' />
																								</button>
																							</td>
																						:
																						<td>
																							<button class="btn padding-btn-dtd watch w-100" title='Add to WatchList' onClick={() => handleAddWatchList(val)} disabled={loading}>
																								<img src="assets/image/watch.png" class="img-fluid" alt='' />
																							</button>
																						</td>
																				}
																			</tr>
																		)
																	})
																}
															</tbody>
														</table>
														<Pagination
															currentPage={currentPageClose}
															totalPages={totalPagesClose}
															onPageChange={handlePageChangeClose}
															setPagination={setPaginationClose}
															pageNumber={pageNumberClose}
															pageSize={pageSizeClose}
															pagination={paginationClose}
														/>
													</div>
												</div>
												<div class="tab-pane fade" id="pills-contact" role="tabpanel"
													aria-labelledby="pills-contact-tab">
													<div class="table-responsive text-center">
														<table class="table table-bordered text-center">
															<thead>
																<tr>
																	<th scope="col" className='color-grey'>Date</th>
																	<th scope="col" className='color-grey'>Forex Name</th>
																	<th scope="col" className='color-grey'>Forex Code</th>

																	<th scope="col" className='color-grey'>View</th>
																	<th scope="col" className='color-grey'>Delete</th>
																</tr>
															</thead>
															<tbody>
																{
																	watchList.map((val, ind) => {
																		return (
																			<tr key={ind}>
																				<th scope="row">{moment(val.createdDate).format('DD MMM YYYY')} </th>
																				<td>{val.stockName}</td>
																				<td>{val.stockCode}</td>
																				<td><button class="btn padding-btn-dtd open-btn w-100" title='View Chart' data-bs-toggle="modal" data-bs-target="#StockView" onClick={() => setTradeCode(val.stockCode)}> <img src="assets/image/folder.png" class="img-fluid" alt='' /> </button> </td>
																				<td><button class="btn padding-btn-dtd delete-admin" title="Delete" data-bs-toggle="modal" data-bs-target="#deleteModel" onClick={() => handleRemoveWatchList(val.watchListId)}> <i class="bi bi-trash3-fill"></i> </button></td>
																			</tr>
																		)
																	})
																}

															</tbody>
														</table>
														<Pagination
															currentPage={currentPageWatch}
															totalPages={totalPagesWatch}
															onPageChange={handlePageChangeWatch}
															setPagination={setPaginationWatch}
															pageNumber={pageNumberWatch}
															pageSize={pageSizeWatch}
															pagination={paginationWatch}
														/>
													</div>
												</div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* <Loader/> */}
			</div>
			<ViewImage chartImg={chartImg} />
			<DeleteConfirmation deleteConfirm={deleteConfirm} type={type} />
			<RemoveTrade deleteConfirmRemove={deleteConfirmRemove} type={type} ConfirmRemoveData ={ConfirmRemoveData} />
			<CloseTrade deleteConfirmClose={deleteConfirmClose} type={type} deleteConfirmData ={deleteConfirmData}/>

			<BuySellUpdateTrade handleGetOpenTradelist={handleGetOpenTradelist} onSave={handleDataUpdate} setBuySellTradeData={setBuySellTradeData} buySellTradeData={buySellTradeData} portFolipID={portFolipID} portfololioList={portfololioList} />
			<OrderHistory transactionId={transactionId} stockcurrencycode={stockcurrencycode} portfoliocurrencycode={portfoliocurrencycode} />
			{/* <EditTrade setTradeListRun={setTradeListRun} setTradeData={setTradeData} tradeData={tradeData} portfololioList={portfololioList} providerList={providerList} /> */}
		</>
	)
}

export default TradeTable