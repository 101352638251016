import React, { useState, useEffect } from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from "yup";
import moment from 'moment/moment'
import { addAmountAPI, getTransactionListAPI } from '../../../services/ManageAccountServices';
import { GetCurrentBalabce } from '../../../services/ProviderServices';
import Pagination from '../../commonComponent/Pagination';
import { toast } from 'react-toastify';
import {  GetExchangeRate, GetExchangeRateTrade, GetRapidExchangeRate, GetRapidExchangeRateTrade,  getOpentradeList } from '../../../services/TradeService';


const ManageAccount = ({ countryList, runAgainDashBoard, setRunAgainDashBoard, setRunBalancsTranAPI, portfololioList, userType }) => {
  const [accountInput, setAccountInput] = useState({
    type: '', amount: '', broker: `${portfololioList[0]?.portfolioId},${portfololioList[0]?.providerId}`,
    userId: userType?.userId,
    portfolioId: portfololioList[0]?.portfolioId,
    country: "",
    providerId: 0,
    transDate: new Date(),
    comments: "",
    createdBy: '',
    startingBalance: ""
  })
  const { broker, portfolioId } = accountInput
  const [tranTypeList, setTranTypeList] = useState([])
  const [transactionList, setTransactionList] = useState([])
  const [balanceDetails, setBalanceDetails] = useState('')
  const [pagination, setPagination] = useState({ currentPage: 1, pageNumber: 1, pageSize: 10, totalPages: 0 })
  const { pageNumber, pageSize, currentPage, totalPages } = pagination
  const [usertradeData, setUsertradeData] = useState({ cfd: 0, profit: 0, balanceInShare: 0 })
  const { cfd, profit } = usertradeData
  console.log("profit", profit)
  const [buttonDisable, setButtonDisable] = useState(false)
  const [currencySymbol, setCurrencySymbol] = useState('')


  useEffect(() => {
    setAccountInput({ ...accountInput, portfolioId: portfololioList[0]?.portfolioId })
    setCurrencySymbol({ symbol: portfololioList[0]?.currencySymbol, code: portfololioList[0]?.currency })
  }, [portfololioList])

  const handlePageChange = (newPage) => {
    setPagination({ ...pagination, currentPage: currentPage + newPage })
    // Perform data fetching or other actions when page changes
  };

  const getTranBalance = () => {

    let paramData = {
      PortfolioId: Number(portfolioId),
      id: userType?.userId,
      pageNumber: currentPage,
      pageSize: pageSize
    }

    if (broker != '' && paramData.id !== undefined && paramData.PortfolioId !== undefined) {
      try {
        getTransactionListAPI(paramData).then((res) => {
          if (res?.status === 200) {

            setTransactionList(res.data.data)
            setPagination({ ...pagination, totalPages: res.data.totalPages })
          }
        })
      } catch (error) {

      }
      let data = {
        userId: userType?.userId,
        portfolioId: portfolioId
      }
      if (data.userId !== undefined && portfolioId !== undefined && portfolioId !== undefined) {
        try {
          GetCurrentBalabce(data).then((res) => {
            if (res?.status === 200) {
              console.log("setBalanceDetails", res.data);
              setBalanceDetails(res.data)
            }
          })
        } catch (error) {

        }
      }
    }
  }
  useEffect(() => {
    getTranBalance()
  }, [portfolioId, currentPage])

  const getShareValue = () => {
    let paramData = {
      id: userType?.userId,
      pageNumber: 1,
      pageSize: 500,
      portfolioId: portfolioId
    }

    if (paramData.id !== undefined &&paramData.portfolioId !== undefined ) {
      try {
        getOpentradeList(paramData).then((res) => {
          if (res?.status === 200) {
            let dta = res.data.data
            let bs = 0
            let bcfd = 0
            let pft = 0

            if (dta.length > 0) {

              for (let i = 0; i < dta.length; i++) {
                let qty = Number(dta[i].quantity)
                let transp = Number(dta[i].valueInShares)
                // try {
                //   getSearchStockDetails(dta[i].stockCode).then((res) => {

                //     if (res?.status === 200) {

                //       if (res.data.length > 0) {
                  let stockArr = dta[i].stockCode.split('/')
                try {
                  GetRapidExchangeRateTrade(dta[i].stockCode).then(
                    (respons) => {
                      // GetExchangeRateTrade(dta[i].stockCode).then((respons) => {
                      console.log("respons", respons, dta[i].stockCode);
                      let expPrm = {
                        CurrencyFrom: stockArr[1],
                        CurrencyTo: currencySymbol.code,
                      };
                      try {
                        // GetExchangeRate().then((resp) => {
                        GetRapidExchangeRate(expPrm).then((resp) => {
                          console.log("resp", resp, expPrm);
                          const exPrice = Number(
                            resp?.data?.rates?.[expPrm?.CurrencyTo]
                          );
                          const bidPrice =
                            respons.data?.rates?.[
                              dta[i].stockCode.split("/")[1]
                            ];
                          const askPrice =
                            respons.data?.rates?.[
                              dta[i].stockCode.split("/")[1]
                            ];

                          if (dta[i].short == "buy") {
                            let cls = askPrice * exPrice;
                            pft = pft + cls * qty - transp;
                          } else {
                            let cls = bidPrice * exPrice;
                            pft = transp - (pft + cls * qty);
                          }

                          bcfd = bcfd + dta[i].valueInCFD;
                          bs = bs + Number(dta[i]?.valueInShares);
                          const twoDecimal = (value) => {
                            var roundedNumber = value;
                            var decimalIndex = roundedNumber.indexOf(".");
                            if (decimalIndex !== -1) {
                              var decimalPlaces =
                                roundedNumber.length - decimalIndex - 1;
                              if (decimalPlaces > 2) {
                                roundedNumber = roundedNumber.slice(
                                  0,
                                  decimalIndex + 3
                                );
                              }
                            }
                            return roundedNumber;
                          };
                          let roundedBS = twoDecimal(bs.toString());
                          let roundedBCFD = twoDecimal(bcfd.toString());
                          let roundedPFT = twoDecimal(pft.toString());
                          console.log("balanceInShare", Number(roundedBS));
                          setUsertradeData({
                            cfd: Number(roundedBCFD),
                            profit: Number(roundedPFT),
                            balanceInShare: Number(roundedBS),
                          });
                        });
                      } catch (error) {}
                    }
                  );
                } catch (error) {

                }
                //       }
                //     }
                //   })
                // } catch (error) {

                // }
              }
            } else {

            }
          }
        })
      } catch (error) {

      }
    }
  }

  useEffect(() => {
    getShareValue()
  }, [portfolioId])

  

  const handleManageAccountCancel = () => {
    setPagination({ ...pagination, currentPage: 1, pageNumber: 1, })
    setAccountInput({
      ...accountInput,
      type: '', amount: '',
      userId: userType?.userId,
      country: "",
      providerId: 0,
      transDate: new Date(),
      comments: "",
      createdBy: '',
      startingBalance: ""
    })
  }

  // const dipositWithdrawalBalance = (data) => {
  //   try {
  //     addAmountAPI(data).then((res) => {

  //       if (res?.status === 200) {
  //         getTranBalance()
  //         // setRunAgainDashBoard(runAgainDashBoard + 1)
  //         setTimeout(() => {
  //           setAccountInput({
  //             ...accountInput,
  //             type: '', amount: '',
  //             userId: userType?.userId,
  //             country: "",
  //             providerId: 0,
  //             transDate: new Date(),
  //             comments: "",
  //             createdBy: '',
  //             startingBalance: ""
  //           })

  //         }, 1000)
  //       } else {

  //       }
  //     })
  //   } catch (error) {

  //   }
  //   setButtonDisable(false)
  // }

  const dipositWithdrawalBalance = async (data) => {
    setButtonDisable(true);
  
    try {
      const res = await addAmountAPI(data);
  
      if (res?.status === 200) {
        getTranBalance();
        // setRunAgainDashBoard(runAgainDashBoard + 1)
        setTimeout(() => {
          setAccountInput({
            ...accountInput,
            type: '',
            amount: '',
            userId: userType?.userId,
            country: "",
            providerId: 0,
            transDate: new Date(),
            comments: "",
            createdBy: '',
            startingBalance: ""
          });
        }, 1000);
      } else {
        // Handle error if needed
      }
    } catch (error) {
      // Handle error if needed
    } finally {
      setButtonDisable(false);
    }
  };

  const onAddAmount = (data) => {
    setButtonDisable(true)
    setRunBalancsTranAPI(true)
    data.userId = userType?.userId
    if (data.type === "Withdrawal") {
      if (balanceDetails.currentBalance >= data.amount) {

        dipositWithdrawalBalance(data)
      } else {
        toast.error("You don't have enough balance.")
        handleManageAccountCancel()
        setButtonDisable(false)
      }

    } else if (data.type === "Deposit") {

      dipositWithdrawalBalance(data)
    }

  }

  let validationSchema = Yup.object().shape({
    type: Yup.string().required('Type is required'),
    amount: Yup.number("Must ba a number").required('Amount is required').typeError('Amount must be a number'),
    broker: Yup.string().required('Broker is required')
  });


  const handleSelectBroker = (e) => {
    let selectedProtfolio = portfololioList.filter((val) => val.portfolioId == Number(e.target.value))

    setCurrencySymbol({ symbol: selectedProtfolio[0]?.currencySymbol, code: selectedProtfolio[0]?.currency })
    setAccountInput({
      ...accountInput,
      portfolioId: e.target.value, amount: '', comments: ''
    })
    setUsertradeData({ cfd: 0, profit: 0, balanceInShare: 0 })
  }
  const handleManageAccountInput = (e) => {
    if (e.target.name == 'amount') {
      if ((Number(e.target.value) >= 0 || e.target.value == '') || e.target.value == '.') {
        let n = e.target.value.split('.')
        var roundedNumber = e.target.value;
        var decimalIndex = roundedNumber.indexOf(".");
        if (decimalIndex !== -1) {
          var decimalPlaces = roundedNumber.length - decimalIndex - 1;
          if (decimalPlaces > 2) {
            roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
          }
        }
        if (n.length == 2) {
          if (n[0].length <= 10) {
            setAccountInput({
              ...accountInput,
              [e.target.name]: roundedNumber
            })
          }
        } else if (e.target.value.length <= 10) {

          setAccountInput({
            ...accountInput,
            [e.target.name]: roundedNumber
          })
        }
      }
    } else if (e.target.name == 'broker') {
      setAccountInput({
        ...accountInput,
        [e.target.name]: e.target.value
      })
      setUsertradeData({ cfd: 0, profit: 0 })
    } else {
      setAccountInput({
        ...accountInput,
        [e.target.name]: e.target.value
      })
    }

  }

  const handleBlurManageAcc = () => {

  }
  return (
    <>
      <div className="modal fade backdrop-1" data-bs-backdrop="static" id="manageAccount" tabindex="-1" aria-labelledby="exampleModalLgLabel" style={{ 'display': 'none' }} aria-modal="true" role="dialog">
        <div className="modal-dialog modal-lg  modal-dialog-centered">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title h4" id="exampleModalLgLabel">Manage Account</h5>
              <button type="button" onClick={handleManageAccountCancel} className="btn-close btn-close-provider" data-bs-dismiss="modal" aria-label="Close">×</button>
            </div>
            <div className="modal-body">
              <Formik
                initialValues={accountInput}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(value) => {
                  if (value.amount > 0) {
                    onAddAmount(value)
                  } else {
                    toast.error("Please enter amt greater than 0.")
                  }

                }}
              >
                {({ errors, touched }) => (
                  <Form className='row p-3'>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="postion-bx-min mt-2 ">
                          <span class="frm-hd-t">Account Transaction</span>
                        </div>
                        <div class="row">
                          <div class="col-md-12 mt-1">
                            <div class="postion-min-dta">
                              <div className='row mb-2'>
                                <div class="col-12 col-sm-7 ">
                                  <label>Portfolio Name </label>
                                  <Field name='portfolioId' as='select' className="form-select" onChange={handleSelectBroker}>
                                    {/* <option value="0">Select Broker</option> */}
                                    {
                                      portfololioList?.map((val, ind) => {
                                        return (
                                          <option className='option-width' key={ind} value={val.portfolioId}>{val.portfolioName}</option>
                                        )
                                      })
                                    }
                                  </Field>
                                  {errors.portfolioId && touched.portfolioId ? (
                                    <div className='color-msg-err'>{errors.portfolioId}</div>
                                  ) : null}
                                </div>
                                <div class="col-12 col-sm-5 text-center">
                                  <p className='starting_main white_text'> Starting Balance :
                                    {
                                      transactionList?.map((val, id) => {
                                        return (
                                          val.type == "Starting Balance" && <p key={id} className='starting_b'> {currencySymbol.symbol} {val.amount}  {moment(val.transDate).format('DD MMM YYYY')}</p>
                                        )
                                      })
                                    }
                                  </p>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-7">
                                  <div class="postion-bx-min mt-2 ">
                                    <span class="frm-hd-t">Enter Manage Account Details</span>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 mt-1">
                                      <div class="postion-min-dta postion-frm">
                                        <div class="row">
                                          {/* <!-- -----  COLmd-8--> */}
                                          <div class="col-md-6">
                                            <div class="row mb-3">
                                              <label for="inputEmail3" class="col-sm-12 col-form-label">Type <span style={{ "color": "#f00" }}>*</span></label>
                                              <div class="col-sm-12 ms-auto">
                                                <Field name='type' as='select' onBlur={handleBlurManageAcc} className="form-select" onChange={handleManageAccountInput}>
                                                  <option value={''}>Select Type</option>
                                                  <option value="Deposit" >Deposit</option>
                                                  <option value="Withdrawal" >Withdrawal</option>
                                                  {/* {
                                                    tranTypeList.map((tranType, index) => {
                                                      return (
                                                        <option value={tranType.TranTypeId} key={index}>{tranType.TranTypeName}</option>
                                                      )
                                                    })
                                                  } */}
                                                </Field>
                                                {errors.type && touched.type ? (
                                                  <div className='color-msg-err'>{errors.type}</div>
                                                ) : null}
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-6 ">
                                            <div class="row mb-2">
                                              <label for="inputEmail3" class="col-sm-12 col-form-label">Amount <span style={{ "color": "#f00" }}>*</span></label>
                                              <div class="col-sm-12 ms-auto">
                                                <Field type="text" name='amount' onBlur={handleBlurManageAcc} value={accountInput?.amount} className="form-control" onChange={handleManageAccountInput} />
                                                {errors.amount && touched.amount ? (
                                                  <div className='color-msg-err'>{errors.amount}</div>
                                                ) : null}
                                                {/* <input type="text" name="date" class="form-control" id="inputEmail3" /> */}
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-12">
                                            <div class="row mb-2 comments-input">
                                              <label for="inputEmail3" class="col-sm-12 col-form-label">Comments</label>
                                              <div class="col-sm-12 ms-auto">
                                                <Field type="text" as="textarea" name='comments' value={accountInput?.comments} className="form-control" id="formGroupExampleInput" onChange={handleManageAccountInput} />
                                                {errors.comments && touched.comments ? (
                                                  <div className='color-msg-err'>{errors.comments}</div>
                                                ) : null}
                                                {/* <textarea class="form-control"></textarea> */}
                                              </div>
                                            </div>
                                          </div>

                                          <div class="row">
                                            <div class="col-sm-4">
                                              <button type="submit" disabled={buttonDisable} class="btn commi-btn mt-4 mb-2 me-auto d-block text-white">Save</button>
                                            </div>
                                            <div class="col-sm-4">
                                              <button type="button" onClick={handleManageAccountCancel} class="btn commi-btn mt-4 mb-2 me-auto d-block text-white">Cancel</button>
                                            </div>
                                          </div>

                                          {/* <!-- -----  col-md-5--> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <!-- -----  account Summary--> */}
                                <div class="col-md-5">
                                  <div class="postion-bx-min mt-2 ">
                                    <span class="frm-hd-t">Account Summary <span className='currency_code'>({currencySymbol.code})</span></span>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 mt-1">
                                      <div class="postion-min-dta postion-frm">
                                        <div class="col-md-12">
                                          <div class="row mb-3">
                                            <label for="inputEmail3" class="col-sm-7 col-form-label ">Current Balance</label>
                                            <div class="col-sm-5 ms-auto">
                                              <label for="inputEmail3" class="col-form-label ">{currencySymbol?.symbol} {((Number(balanceDetails.currentBalance) + Number(usertradeData.balanceInShare) + (profit || 0))).toFixed(2)}</label>
                                            </div>
                                          </div>
                                          <div class="row mb-3 comments-input">
                                            <label for="inputEmail3" class="col-sm-7 col-form-label ">Balance in Margin</label>
                                            <div class="col-sm-5 ms-auto">
                                              <label for="inputEmail3" class="col-form-label ">{currencySymbol?.symbol} {cfd}</label>
                                            </div>
                                          </div>
                                          <div class="row mb-3 comments-input">
                                            <label for="inputEmail3" class="col-sm-7 col-form-label ">Balance In Cash</label>
                                            <div class="col-sm-5 ms-auto">
                                              <label for="inputEmail3" class="col-form-label ">{currencySymbol?.symbol} {Number(balanceDetails.currentBalance)}</label>
                                            </div>
                                          </div>
                                          <div class="row mb-3 comments-input">
                                            <label for="inputEmail3" class="col-sm-7 col-form-label ">Profit/Loss</label>
                                            <div class="col-sm-5 ms-auto">
                                              <label for="inputEmail3" class="col-form-label ">{currencySymbol?.symbol} {profit || 0}</label>
                                            </div>
                                          </div>
                                          <div class="row mb-3 comments-input">
                                            <label for="inputEmail3" class="col-sm-7 col-form-label ">Balance In Forex</label>
                                            <div class="col-sm-5 ms-auto">
                                              <label for="inputEmail3" class="col-form-label ">{currencySymbol?.symbol} {Number(usertradeData.balanceInShare)}</label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <!-- -----  account Summary--> */}
                              </div>
                              <div class="row mt-5">
                                <div class="col-sm-12">
                                  <div class="postion-bx-min mt-2 ">
                                    <span class="frm-hd-t">Account Summary <span className='currency_code'>({currencySymbol.code})</span></span>
                                  </div>
                                  <div class="row mt-1">
                                    <div class="col-sm-12">
                                      <div class="postion-min-dta postion-frm">
                                        <div class="table-responsive text-center ">
                                          <div class="table-input1 ">
                                            <table class="table text-center  table-input-data1">
                                              <thead>
                                                <tr class="border-bottom-0">
                                                  <th scope="row" class="ad-tab-b color-grey ">Transaction Id</th>
                                                  <th scope="row" class="ad-tab-b color-grey ">Type</th>
                                                  <th scope="row" class="ad-tab-b color-grey">Date</th>
                                                  <th scope="row" class="ad-tab-b color-grey">StockName</th>
                                                  <th scope="row" class="ad-tab-b color-grey">Amount</th>
                                                  <th scope="row" class="ad-tab-b color-grey">Running Transaction</th>
                                                  <th scope="row" class="ad-tab-b color-grey">Comments</th>
                                                  {/* <th scope="row" class="ad-tab-b">Edit</th> */}
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {
                                                  transactionList.map((val, ind) => {
                                                    {/* console.log(val , "transactionlist") */}
                                                    return (
                                                      <tr key={ind} className='white_text' >
                                                        <th>{val.transactionNo}</th>
                                                        <td>{val.type}</td>
                                                        <td>{moment(val.transDate).format('DD MMM YYYY')}</td>
                                                        <td></td>
                                                        <td>{currencySymbol?.symbol} {val.amount.toFixed(2)}</td>
                                                        <td>{currencySymbol?.symbol} {(val.runningBalance).toFixed(2)}</td>
                                                        <td>{val.comments}</td>
                                                        <td>
                                                          {/* <button class="btn padding-btn-dtd edit-btn-adm" title="Edit">
                                                            <img src="assets/image/edit1.png" class="img-fluid" /></button> */}
                                                        </td>
                                                      </tr>
                                                    )
                                                  })
                                                }

                                              </tbody>
                                            </table>
                                            <Pagination
                                              currentPage={currentPage}
                                              totalPages={totalPages}
                                              onPageChange={handlePageChange}
                                              setPagination={setPagination}
                                              pageNumber={pageNumber}
                                              pageSize={pageSize}
                                              pagination={pagination}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <!-- ----- min show data crate only this--> */}
                          </div>
                        </div>
                      </div>
                    </div>

                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ManageAccount