import axios from 'axios';
import process from "process";
import NotificationToast from '../components/commonComponent/NotificationToast';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_BASE_URL
const API_KEY = process.env.REACT_APP_API_KEY
const APPLICATION_KEY = process.env.REACT_APP_APPLICATION_KEY
const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,PATCH,OPTIONS',
    'Authorization':API_KEY
}


export const gettradeList = async (data) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetTrades?UserId=${data?.id}&pageNumber=${data?.pageNumber}&pageSize=${data?.pageSize}${data.search.length>0?`&searchText=${data.search}`:''}&ApplicationKey=${APPLICATION_KEY}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const getInflationRate = async (data) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Forex/getInflationRate`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const GetInvestmentReturnWeekly = async (data) => {
    
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Forex/GetInvestmentReturnWeekly/${data.CurrencyFrom}/${data.CurrencyTo}&ApplicationKey=${APPLICATION_KEY} `,
            mode: 'cors',
            headers,
            
        });
        
       if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const GetInvestmentReturnMonthly = async (data) => {
    
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Forex/GetInvestmentReturnMonthly/${data.CurrencyFrom}/${data.CurrencyTo}&ApplicationKey=${APPLICATION_KEY} `,
            mode: 'cors',
            headers,
            
        });
        
       if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const getorderHistory = async (data) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetTradeHistory?TransactionNo=${data}&ApplicationKey=${APPLICATION_KEY}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const gettradeCountryList = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetTradeCountry?ApplicationKey=${APPLICATION_KEY}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const getOpentradeCountryList = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetOpenTradeCountry?UserId=${data?.id}&ApplicationKey=${APPLICATION_KEY}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const getClosetradeList = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetCloseTradeList?UserId=${data.id}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&ApplicationKey=${APPLICATION_KEY}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}


export const GetOpenTradeListByUserId = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetOpenTradeList?UserId=${data.id}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&Country=${data.Country}&ApplicationKey=${APPLICATION_KEY}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const modifyOpenTrade = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'PUT',
            url: `${API_URL}/Trades/ModifyTrade?TradeId=${data.TradeId}`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}
export const GetExchangeRate = async (data) => {
    
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Forex/GetExchangeRate/${data.CurrencyFrom}/${data.CurrencyTo}&ApplicationKey=${APPLICATION_KEY} `,
            mode: 'cors',
            headers,
            
        });
        
       if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const GetExchangeRateTrade = async (data) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${API_URL}/Forex/GetExchangeRate/${data}&ApplicationKey=${APPLICATION_KEY}`,
      mode: "cors",
      headers,
    });

    if (response?.status === 200) {
      return response;
    } else if (response?.status === 401) {
      alert("Not authorized.");
    } else if (response?.status >= 500) {
      alert("Something went wrong");
    }
  } catch (error) {}
};

export const GetRapidExchangeRate = async (data) => {
    console.log("apiData", data)
  try {
    const response = await axios({
      method: "GET",
      url: `${API_URL}/Rapid/GetExchangeRate/${data.CurrencyFrom}/${data.CurrencyTo}&ApplicationKey=${APPLICATION_KEY} `,
      mode: "cors",
      headers,
    });

    if (response?.status === 200) {
      return response;
    } else if (response?.status === 401) {
      alert("Not authorized.");
    } else if (response?.status >= 500) {
      alert("Something went wrong");
    }
  } catch (error) {}
};

export const GetRapidExchangeRateTrade = async (data) => {
  console.log("apiData", data);
  try {
    const response = await axios({
      method: "GET",
      url: `${API_URL}/Rapid/GetExchangeRate/${data}&ApplicationKey=${APPLICATION_KEY}`,
      mode: "cors",
      headers,
    });

    if (response?.status === 200) {
      return response;
    } else if (response?.status === 401) {
      alert("Not authorized.");
    } else if (response?.status >= 500) {
      alert("Something went wrong");
    }
  } catch (error) {}
};


export const RemoveUserTrade = async (TradeId , UserId) => {
    debugger
    try {
        const response = await axios({
            method: 'DELETE',
            url: `${API_URL}/Trades/RemovePendingTrade?TradeId=${TradeId}&UserId=${UserId}`,

            mode: 'cors',
            headers,
            
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const getOpentradeList = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetOpenTrades?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&UserId=${data.id}&PortfolioId=${data.portfolioId}&ApplicationKey=${APPLICATION_KEY}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}
export const updateOpenTrade = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'PUT',
            url: `${API_URL}/Trades/UpdateOpenTrade?transactionNo=${data.transactionNo}&ApplicationKey=${APPLICATION_KEY}`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}
export const getOpentradeListFinancelYear = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetUserTrades?PageNumber=${data.pageNumber}&PageSize=${data.pageSize}&UserId=${data.id}&PortfolioId=${data.portfolioId}&dateFrom=${data.dateFrom}&dateTo=${data.dateTo}&ApplicationKey=${APPLICATION_KEY}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const deleteTrade = async (id) => {
    try {
        const response = await axios({
            method: 'DELETE',
            url: `${API_URL}/Trades/DeleteTrade?id=${id}&ApplicationKey=${APPLICATION_KEY}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}
// export const closeOpenTrade = async (id) => {
//     try {
//         const response = await axios({
//             method: 'post',
//             url: `${API_URL}/Trades/CloseTrade?tradeid=${id}&ApplicationKey=${APPLICATION_KEY}`,
//             mode: 'cors',
//             headers,
//             // data: {
//             //   firstName: 'Fred',
//             //   lastName: 'Flintstone'
//             // }
//         });
//         if (response?.status === 200) {
//             NotificationToast(response.data.message)
//             return response
//         }
//         else if (response?.status === 401) {
//             alert('Not authorized.')
//         } else if (response?.status >= 500) {
//             alert('Something went wrong')
//         }

//     } catch (error) {
//         toast.error('Something went wrong. Please try again later.')
//     }
// }

export const closeOpenTrade = async (data) => {
    try {
        const response = await axios({
            method: 'post',
            url: `${API_URL}/Trades/CloseTrade?TradeId=${data.TradeId}&CloseWithMyPrice=${data.CloseWithMyPrice}&ClosePrice=${data.ClosePrice}`,
            mode: 'cors',
            headers,
           data: data
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}
export const editTrade = async (value,id) => {
    let data={...value,ApplicationKey:APPLICATION_KEY}
    // 
    try {
        const response = await axios({
            method: 'PUT',
            url: `${API_URL}/Trades/UpdateTrade?TradeId=${id}`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const getWatchList = async (data) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Trades/GetWatchList?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&UserId=${data.id}&ApplicationKey=${APPLICATION_KEY}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const removeWatchList = async (id) => {
    try {
        const response = await axios({
            method: 'DELETE',
            url: `${API_URL}/Trades/DeleteWatchList?id=${id}&ApplicationKey=${APPLICATION_KEY}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const AddWatchList = async (value) => {
    let data={...value,ApplicationKey:APPLICATION_KEY}
    try {
        const response = await axios({
            method: 'POST',
            url: `${API_URL}/Trades/AddWatchList`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}

export const getStockDetails = async (data) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockDetails/${data.tradeCode}/${data.stockDate}?ApplicationKey=${APPLICATION_KEY}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}


export const getStockChartDetails = async () => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockDetails/AAPL/1w?ApplicationKey=${APPLICATION_KEY}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getStockNews = async (tradeCode) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/Forex/GetForexNews/FOREX:${tradeCode}?ApplicationKey=${APPLICATION_KEY}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getRapidStockNews = async (tradeCode) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${API_URL}/Rapid/GetNews/${tradeCode}`,
      mode: "cors",
      headers,
      // data: {
      //   firstName: 'Fred',
      //   lastName: 'Flintstone'
      // }
    });
    if (response?.status === 200) {
      return response;
    } else if (response?.status === 401) {
      alert("Not authorized.");
    } else if (response?.status >= 500) {
      alert("Something went wrong");
    }
  } catch (error) {}
};


export const getStockIncomeStatement = async (tradeCode) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockIncomeStatement/${tradeCode}/30?ApplicationKey=${APPLICATION_KEY}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}



export const getStockBalanceSheet = async (tradeCode) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockBalanceSheet/${tradeCode}/30?ApplicationKey=${APPLICATION_KEY}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}



export const getStockCashFlow = async (tradeCode) => {
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockCashFlow/${tradeCode}/30?ApplicationKey=${APPLICATION_KEY}`,
            mode: 'cors',
            headers,
            // data: {
            //   firstName: 'Fred',
            //   lastName: 'Flintstone'
            // }
        });
        if (response?.status === 200) {
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}


export const AddOpenTradeAPI = async (value) => {
    let data={...value,ApplicationKey:APPLICATION_KEY}
    try {
        const response = await axios({
            method: 'POST',
            url: `${API_URL}/Trades/AddOpenTrade`,
            mode: 'cors',
            headers,
            data: JSON.stringify(data)
        });
        if (response?.status === 200) {
            
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        toast.error('Something went wrong. Please try again later.')
    }
}


export const getDevident = async (data) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockDividends/${data.tradeCode}/${data.interval}?ApplicationKey=${APPLICATION_KEY}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}

export const getStockStats = async (stockCode) => {
    // 
    try {
        const response = await axios({
            method: 'GET',
            url: `${API_URL}/IEXStock/GetStockStats/${stockCode}?ApplicationKey=${APPLICATION_KEY}`,
            mode: 'cors',
            headers,
        });
        if (response?.status === 200) {
            NotificationToast(response.data.message)
            return response
        }
        else if (response?.status === 401) {
            alert('Not authorized.')
        } else if (response?.status >= 500) {
            alert('Something went wrong')
        }

    } catch (error) {
        
    }
}