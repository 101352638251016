import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { getStockList } from '../../../services/HomeService'
import { AsyncPaginate } from 'react-select-async-paginate';
import AsyncLoadingSelect from '../../commonComponent/AsyncLoadingSelect';
import { GetExchangeRate, GetRapidExchangeRate, GetRapidExchangeRateTrade, GetExchangeRateTrade, getOpentradeList, getStockDetails } from '../../../services/TradeService';
import { getSearchStockDetails } from '../../../services/HomeService';
// import CreateTrade from './CreateTrade';
import { GetCurrentBalabce, GetProviderCummission, GetProviderstocks } from '../../../services/ProviderServices';
import { getTransactionListAPI } from '../../../services/ManageAccountServices';
import CummissionDetails from '../../commonComponent/commonModels/CummissionDetails'
import { toast } from 'react-toastify';
import { Formik, Field, Form, useFormikContext } from 'formik'
import * as Yup from "yup";
import CountryCurrencyList from '../../commonComponent/CountryCurrencyList';


const TradeCalculater = ({ userType, countryList, setOpenTradeRun, calculatedData, calculatedTradeData, setCalculatedTradeData, portfololioList, setRunPortFolioAPI, providerList, setCalculatedData }) => {

    const [brokerList, setBrokerList] = useState(portfololioList)
    const [long, setLong] = useState('sell')
    const [providerCummission, setProviderCummission] = useState([])
    const [accountData, setAccountData] = useState({ profit: 0, balanceinShares: 0, balanceinCFD: 0, leverage: 0 })
    const [selectedPort, setSelectedPort] = useState()
    const [balanceDetails, setBalanceDetails] = useState({
        currentBalance: 0,
        dipositBalanceTill: 0,
        id: 0,
        portfolioId: 0,
        tradeBalanceTill: 0,
        userId: 0,
        withdrawalBalanceTill: 0
    })
    const [currencyCodes, setCurrencyCodes] = useState({ stockCountryCode: '', portfolioCountryCode: '', portfolioCurrency: '', stockCountrySymbol: '' })
    const { stockCountryCode, portfolioCountryCode, portfolioCurrency, stockCountrySymbol } = currencyCodes
    const [transactionList, setTransactionList] = useState([])
    const [profit, setProfit] = useState(0)
    const [stocksList, setStocksList] = useState([])
    const [quantity, setQuantity] = useState('')//------------------------->
    const [erew, setErew] = useState('')//------------------------->
    const [prefillValue, setPrefillValue] = useState({ stockName: '', currentPrice: '', stockCode: '' })
    const { currentPrice, stockCode } = prefillValue
    const [selectedStock, setSelectedStock] = useState('')
    const [selectedStockr, setSelectedStockr] = useState('')
    const [showStockListDropDown, setShowStockListDropDown] = useState(false)
    const [allData, setAllData] = useState({ riskPer: '', tradeId: 0, stockName: '', portFolioId: '', providerName: '', providerId: '', cummission: 0, entry: '', share: 0, margin: '', risk: '', cfd: '', stopLoss: '', quantity, target: '', type: 'TRADES', reward: 0, rewardPercent: 0, reRender: 0 })
    const { riskPer, stockName, portFolioId, margin, entry, share, providerId, risk, stopLoss, cfd, target, cummission, reward, rewardPercent } = allData
    const [stopLossTableData, setStopLossTableData] = useState({
        dValueATR: 0, dValue2ATR: 0, dValuePerCent: 1, dValueDollar: 0, dValuePrice: 0,
        dStopLossATR: 0, dStopLoss2ATR: 0, dStopLossPerCent: 0, dStopLossDollar: 0, dStopLossPrices: 0,
        dRiskDolATR: 0, dRiskDol2ATR: 0, dRiskDolPerCent: 0, dRiskDolDollar: 0, dRiskDolPrice: 0,
        dRiskPerATR: 0, dRiskPer2ATR: 0, dRiskPerPerCent: 0, dRiskPerDollar: 0, dRiskPerPrice: 0,
        dRRRATR: 0, dRRR2ATR: 0, dRRRPerCent: 0, dRRRDollar: 0, dRRRPrice: 0
    })
    const {
        dValueATR, dValue2ATR, dValuePerCent, dValueDollar, dValuePrice,
        dStopLossATR, dStopLoss2ATR, dStopLossPerCent, dStopLossDollar, dStopLossPrices,
        dRiskDolATR, dRiskDol2ATR, dRiskDolPerCent, dRiskDolDollar, dRiskDolPrice,
        dRiskPerATR, dRiskPer2ATR, dRiskPerPerCent, dRiskPerDollar, dRiskPerPrice,
        dRRRATR, dRRR2ATR, dRRRPerCent, dRRRDollar, dRRRPrice
    } = stopLossTableData
    const [stockListPaging, setStockListPaging] = useState({ PageNumber: 1, PageSize: 100, search: '' })
    const { PageNumber, PageSize, search } = stockListPaging
    const [error, setError] = useState({ target: true, stopLoss: true, stockName: true })
    const [loadStockData, setLoadStockData] = useState(false)
    const [exchangeRate, setExchangerate] = useState()
    const [popUpShow, setPopUpShow] = useState(null)
    const [paginationCommi, setPaginationCommi] = useState({ currentPageCommi: 1, pageNumberCommi: 1, pageSizeCommi: 15, totalPagesCommi: 0 })
    const { pageNumberCommi, pageSizeCommi, currentPageCommi, totalPagesCommi } = paginationCommi
    const showToast = (message) => {
        if (popUpShow) {
            toast.dismiss(popUpShow);
            const newToast = toast.error(message, {
                autoClose: 3000, // Set the duration as needed
            });
            setPopUpShow(newToast);
        } else {
            // If no active toast, show a new one
            const newToast = toast.error(message, {
                autoClose: 3000, // Set the duration as needed
            });
            setPopUpShow(newToast);
            // toast.dismiss(newToast); // Update the current toast ID
        }
    };


    useEffect(() => {
        setBrokerList(portfololioList)
    }, [portfololioList])

    useEffect(() => {
        if (calculatedTradeData?.stockCode !== undefined) {
            setLong(calculatedTradeData.long)
            setSelectedStock({ stockCode: calculatedTradeData?.stockCode, })
            // let filteredProvider = providerList.filter((val) => val?.providerName == calculatedTradeData?.providerName)

            if (calculatedTradeData?.stockCode !== undefined) {
                setPrefillValue({
                    ...prefillValue, stockCode: calculatedTradeData?.stockCode, stockName: calculatedTradeData.symbol_id, currentPrice: calculatedTradeData.entry, ask_price: calculatedTradeData.ask_price,
                    ask_size: calculatedTradeData.ask_size, bid_price: calculatedTradeData.bid_price, bid_size: calculatedTradeData.bid_size
                })
                setAllData({
                    ...allData, stockName: calculatedTradeData?.stockCode,
                    stockCode: calculatedTradeData?.stockCode,
                    stopLoss: calculatedTradeData?.stopLoss,
                    country: calculatedTradeData?.country,
                    target: calculatedTradeData?.target,
                    entry: calculatedTradeData?.entry,
                    type: calculatedTradeData?.type,
                    tradeId: calculatedTradeData?.tradeId,
                    margin: selectedStock.margin == '' ? 0 : selectedStock.margin,
                    portFolioId: '',
                    providerName: calculatedTradeData?.providerName,
                    providerId: calculatedTradeData?.providerId,
                    cummission: 0,
                    share: 0,
                    margin: calculatedTradeData?.margin,
                    risk: 0,
                    cfd: '',
                    quantity: calculatedTradeData?.quantity,
                    reward: 0,
                    rewardPercent: 0,
                    tradingSystemId: calculatedTradeData?.tradingSystemId
                })
            }
            // providerName providerId


            // setAllData({
            //     ...allData,
            //     portFolioId: '',
            //     providerName: '',
            //     providerId: filteredProvider[0]?.providerId,
            //     cummission: 0,
            //     entry: calculatedTradeData?.entry,
            //     share: 0,
            //     margin: calculatedTradeData?.margin,
            //     risk: 0,
            //     cfd: 0,
            //     stopLoss: calculatedTradeData?.stopLoss,
            //     quantity: calculatedTradeData?.quantity,
            //     target: calculatedTradeData?.target,
            //     reward: 0,
            //     rewardPercent: 0
            // })
            // if (calculatedTradeData?.providerName != undefined) {
            //     let s = portfololioList.filter((val) => {
            //         // 
            //         if (val.provider.providerName == calculatedTradeData?.providerName) {
            //             return val
            //         }
            //     })
            //     setBrokerList(s)
            //     if (s.length < 1) {
            //         toast.error('Broker not Available for this trade!')
            //     }
            // } else {
            //     setBrokerList(portfololioList)
            // }
        }
    }, [calculatedTradeData])

    const convertTowDigDloat = (value) => {
        if (value > 0) {
            var roundedNumber = parseFloat(value).toFixed(2);
            return roundedNumber
        } else {
            return 0
        }

    }



    // providerList
    const twoDecimal = (value) => {

        var roundedNumber = value;
        var decimalIndex = roundedNumber.indexOf(".");
        if (decimalIndex !== -1) {
            var decimalPlaces = roundedNumber.length - decimalIndex - 1;
            if (decimalPlaces > 5) {
                roundedNumber = roundedNumber.slice(0, decimalIndex + 6);
            }
        }
        return roundedNumber
    }

    const twoDecimalPoint = (value) => {

        var roundedNumber = String(value);
        var decimalIndex = roundedNumber.indexOf(".");
        if (decimalIndex !== -1) {
            var decimalPlaces = roundedNumber.length - decimalIndex - 1;
            if (decimalPlaces > 2) {
                roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
            }
        }
        return roundedNumber
    }
    const handleInput = (e) => {
        if (e.target.name == 'portFolioId') {
            // let filtered=brokerList.filter((val)=>val.portfolioId==Number(bId[1]))
            setError({ target: true, stopLoss: true, stockName: true })
            let portfolioSelected = portfololioList.filter((val) => e.target.value == val.portfolioId)

            setSelectedPort(portfolioSelected[0])
            setCurrencyCodes({ ...currencyCodes, portfolioCountryCode: portfolioSelected[0]?.currency, portfolioCurrency: portfolioSelected[0]?.currencySymbol })
            let portVal = e.target.value
            if (calculatedTradeData !== undefined) {
                let stkArr = stockCode.split('/')

                let prmData = {
                    CurrencyFrom: stkArr[1],
                    CurrencyTo: portfolioSelected[0]?.currency

                }
                try {
                    // GetExchangeRate(prmData).then((respons) => {
                        GetRapidExchangeRate(prmData).then((respons) => {

                        const exPrice = Number(
                          respons?.data?.rates?.[prmData?.CurrencyTo]
                        );

                        setExchangerate(exPrice);
                        // setPrefillValue({
                        //     ...prefillValue, currentPrice: calculatedTradeData.entry * response.result, ask_price: calculatedTradeData.ask_price * response.result,
                        //     bid_price: calculatedTradeData.bid_price * response.result
                        // })
                        setAllData({
                            ...allData, [e.target.name]: portVal,
                            // entry: Number(calculatedTradeData.entry * response.result).toFixed(3),
                            // stopLoss: Number(calculatedTradeData.stopLoss * response.result).toFixed(3),
                            // target: Number(calculatedTradeData.target * response.result).toFixed(3),
                            riskPer: '', risk: '',
                            quantity: ''
                        })
                    })
                } catch (error) {

                }

            } else if (stockCode.length > 0) {
                // stockCountryCode
                let selectedStockCountry = countryList.filter((val) => val.key.toUpperCase() == selectedStock.country.toUpperCase())


                try {
                    // GetExchangeRate(selectedStockCountry[0]?.value, portfolioSelected[0]?.currency).then((respons) => {
                        GetRapidExchangeRate(selectedStockCountry[0]?.value, portfolioSelected[0]?.currency).then((respons) => {
                        let exRate = Number(
                          respons?.data?.rates?.[portfolioSelected[0]?.currency]
                        );
                        // setExchangerate(exRate);
                        setAllData({
                            ...allData, [e.target.name]: portVal,
                            risk: '',
                            riskPer: '',
                            quantity: ''
                        })
                    })
                } catch (error) {

                }


            } else {

                setAllData({ ...allData, [e.target.name]: e.target.value })
            }


        } else if (e.target.name == "providerId") {
            setError({ target: true, stopLoss: true, stockName: true })
            if (Number(e.target.value) > 0) {
                if (portFolioId == '') {
                    showToast('Select portfolio first.')
                } else {
                    let selectedProvider = providerList.filter((val) => Number(e.target.value) == val.providerId)

                    setPrefillValue({
                        ...prefillValue, stockCode: '', currentPrice: '', ask_price: '',
                        ask_size: '', bid_price: '', bid_size: ''
                    })
                    setAllData({
                        ...allData, [e.target.name]: selectedProvider[0].providerId, providerName: selectedProvider[0]?.providerName, stockName: '', country: '', entry: '', margin: '',
                        stopLoss: '', target: '', erew: '', rewardPercent: 0, share: 0, margin: '', cfd: '', cummission: 0
                    })
                    setErew('')
                    setQuantity('')
                }
            } else if (e.target.value == '') {
                setPrefillValue({
                    ...prefillValue, stockCode: '', currentPrice: '', ask_price: '',
                    ask_size: '', bid_price: '', bid_size: ''
                })
                setAllData({
                    ...allData, [e.target.name]: e.target.value, providerName: '', stockName: '', country: '', entry: '', margin: '',
                    stopLoss: '', target: '', erew: '', rewardPercent: 0, share: 0, margin: '', cfd: '', cummission: 0
                })
                setErew('')
                setQuantity('')
            }
            setStockListPaging({ ...stockListPaging, search: '' })
        } else if (e.target.name == "stock") {
            setSelectedStock(JSON.parse(e.target.value))
            setSelectedStockr(e.target.value)
            setShowStockListDropDown(true)
        } else if (e.target.name === 'stopLoss') {
            if ((e.target.value >= 0 || e.target.value == '.') && e.target.value != '') {
                if (portFolioId == '') {
                    showToast('Select portfolio first.')
                } else if (providerId == '') {
                    showToast('Select provider first.')
                } else if (stockCode.length <= 0) {
                    showToast('Select forex code first.')
                } else if (entry == '' && entry <= 0) {
                    showToast('Enter entry price first.')
                } else {


                    let value = twoDecimal(e.target.value)
                    if (long === 'buy') {
                        if (Number(entry) <= Number(e.target.value)) {
                            showToast('Set lower stop loss price than entry while buy trading')
                        } else {
                            let n = e.target.value.split('.')
                            if (n.length == 2) {
                                if (n[0].length <= 10) {
                                    setAllData({ ...allData, [e.target.name]: value })
                                }
                            } else if (e.target.value.length <= 10) {

                                setAllData({ ...allData, [e.target.name]: value })
                            }
                        }
                    } else {
                        let n = e.target.value.split('.')
                        if (n.length == 2) {
                            if (n[0].length <= 10) {
                                setAllData({ ...allData, [e.target.name]: value })
                            }
                        } else if (e.target.value.length <= 10) {

                            setAllData({ ...allData, [e.target.name]: value })
                        }
                    }

                }
            } else if (e.target.value == '') {
                setAllData({ ...allData, [e.target.name]: e.target.value })
            }
        } else if (e.target.name == "entry") {

            if ((e.target.value >= 0 || e.target.value == '.') && e.target.value != '') {
                if (portFolioId == '') {
                    showToast('Select portfolio first.')
                } else if (providerId == '') {
                    showToast('Select provider first.')
                } else if (stockCode.length <= 0) {
                    showToast('Select forex code first.')
                } else {

                    let value = twoDecimal(e.target.value)
                    let n = e.target.value.split('.')

                    if (n.length == 2) {
                        if (n[0].length <= 10) {
                            setAllData({ ...allData, [e.target.name]: value, target: '', stopLoss: '' })
                        }
                    } else if (e.target.value.length <= 10) {

                        setAllData({ ...allData, [e.target.name]: value, target: '', stopLoss: '' })
                    }


                }
            } else if (e.target.value == '') {
                setAllData({ ...allData, [e.target.name]: e.target.value, target: '', stopLoss: '' })
            }


        } else if (e.target.name == 'target') {

            if ((e.target.value >= 0 || e.target.value == '.') && e.target.value != '') {
                let value = twoDecimal(e.target.value)
                let n = e.target.value.split('.')
                if (n.length == 2) {
                    if (n[0].length <= 10) {
                        setAllData({ ...allData, [e.target.name]: value })
                    }
                } else if (e.target.value.length <= 10) {

                    setAllData({ ...allData, [e.target.name]: value })
                }

            } else if (e.target.value == '') {
                setAllData({ ...allData, [e.target.name]: e.target.value })
            }


        } else if (e.target.name == 'riskPer') {
            // debugger
            if ((e.target.value >= 0 || e.target.value == '.') && e.target.value != '') {
                if (portFolioId != '') {

                    if (Number(e.target.value) >= 0 && e.target.value.length > 0 && Number(e.target.value) <= 100) {
                        var roundedNumber = e.target.value;
                        var decimalIndex = roundedNumber.indexOf(".");

                        if (decimalIndex !== -1) {
                            var decimalPlaces = roundedNumber.length - decimalIndex - 1;
                            if (decimalPlaces > 2) {
                                roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
                            }
                        }
                        let totalBal = balanceDetails?.currentBalance
                        let riskDol = totalBal * roundedNumber / 100


                        setAllData({ ...allData, [e.target.name]: roundedNumber, risk: convertTowDigDloat(riskDol) })

                    } else if (e.target.value == '') {
                        setAllData({ ...allData, [e.target.name]: e.target.value, risk: '' })
                    }
                } else {
                    showToast('Select portfolio first.')
                }
            } else if (e.target.value == '') {
                setAllData({ ...allData, [e.target.name]: e.target.value, risk:'' })
            }
            // risk
        } else if (e.target.name == 'risk') {
            if ((e.target.value >= 0 || e.target.value == '.') && e.target.value != '') {
                if (portFolioId != '') {
                    if (Number(e.target.value) >= 0 && e.target.value.length > 0 && Number(e.target.value) < (balanceDetails?.currentBalance + accountData.balanceinShares)) {
                        var roundedNumber = e.target.value;
                        var decimalIndex = roundedNumber.indexOf(".");
                        if (decimalIndex !== -1) {
                            var decimalPlaces = roundedNumber.length - decimalIndex - 1;
                            if (decimalPlaces > 2) {
                                roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
                            }
                        }
                        let totalBal = balanceDetails?.currentBalance + accountData.balanceinShares
                        let riskPers = (roundedNumber * 100) / totalBal


                        setAllData({ ...allData, [e.target.name]: roundedNumber, riskPer: riskPers > 0 ? riskPers.toFixed(2) : riskPers })
                    } else if (e.target.value == '') {
                        setAllData({ ...allData, [e.target.name]: e.target.value, riskPer: '' })
                    }
                } else {
                    showToast('Select portfolio first.')
                }
            } else if (e.target.value == '') {
                setAllData({ ...allData, [e.target.name]: e.target.value })
            }
        } else if (e.target.name == 'cfd') {

            let value = twoDecimal(e.target.value)
            let valLen = e.target.value.split('.')
            if ((Number(e.target.value) >= 0 || e.target.value == '.') && e.target.value !== '' && share > 0) {



                if (valLen.length == 2) {
                    if (valLen[0].length <= 10) {

                        let n = (Number(value) * 100) / share
                        let as = twoDecimal(n.toString())
                        if (value == '.') {
                            setAllData({ ...allData, [e.target.name]: value, margin: '' })
                        } else {

                            setAllData({ ...allData, [e.target.name]: value, margin: as })
                        }
                    }
                } else if (e.target.value.length <= 10) {
                    let n = (Number(value) * 100) / share
                    let as = twoDecimal(n.toString())
                    if (value == '.') {
                        setAllData({ ...allData, [e.target.name]: value, margin: '' })
                    } else {
                        setAllData({ ...allData, [e.target.name]: value, margin: as })
                    }
                }

            } else if ((Number(e.target.value) >= 0 || e.target.value == '.') && share <= 0 && valLen[0].length <= 10) {

                setAllData({ ...allData, [e.target.name]: value, margin: '' })

            } else if (e.target.value == '') {

                setAllData({ ...allData, [e.target.name]: e.target.value, margin: '' })
            }
            // cfd: (share * margin) / 100
        } else if (e.target.name == 'margin') {
            let value = twoDecimal(e.target.value)
            if ((Number(e.target.value) >= 0 && e.target.value !== '') || e.target.value == '.') {
                let valLen = e.target.value.split('.')

                if (valLen.length == 2) {
                    if (valLen[0].length <= 10) {
                        if (value == '.') {
                            setAllData({ ...allData, [e.target.name]: value, cfd: '' })
                        } else {

                            let n = (Number(value) * share) / 100
                            let as = twoDecimal(n.toString())
                            setAllData({ ...allData, [e.target.name]: value, cfd: as })
                        }
                    }
                } else if (e.target.value.length <= 10) {
                    if (value == '.') {
                        setAllData({ ...allData, [e.target.name]: value, cfd: '' })
                    } else {

                        let n = (Number(value) * share) / 100
                        let as = twoDecimal(n.toString())
                        setAllData({ ...allData, [e.target.name]: value, cfd: as })
                    }
                }

            } else if (e.target.value == '') {
                setAllData({ ...allData, [e.target.name]: e.target.value, cfd: '' })
            }
        } else {
            setAllData({ ...allData, [e.target.name]: e.target.value })
        }
    }




    // getting stock list of provider
    useEffect(() => {
        if (providerId !== '' && providerId !== undefined) {
            let data = {
                PageNumber: PageNumber,
                PageSize: PageSize,
                id: providerId,
                search: search
            }
            if( data.id!==undefined && data.id >0 && data.id !=="" ){

         
            try {
                GetProviderstocks(data).then((res) => {
                    if (res?.status === 200) {
                        setStocksList(res.data.data)
                    }
                })
            } catch (error) {

            }
        }
            // try {
            //     GetProviderCummission(providerId).then((res) => {
            //         if (res?.status === 200) {
            //             setProviderCummission(res.data)
            //         }
            //     })
            // } catch (error) {

            // }

        }
    }, [providerId, search, PageSize])

    // getting cummission of provider
    const HandleViewCummission=()=>{
        let paramVal={
            pageNumber:1,
            pageSize:100,
            id:providerId
        }

        if(paramVal.id !==undefined){

      
        try {
            GetProviderCummission(paramVal).then((res) => {
                if (res?.status === 200) {
                    setProviderCummission(res?.data?.data||[])
                }
            })
        } catch (error) {

        }

    }
    }
    useEffect(() => {
        if (providerId !== '' && providerId !== undefined) {
            HandleViewCummission()

        }
    }, [providerId])
    const getSearchStockData = (selectedStock) => {

        let selectedStockCountry = countryList.filter((val) => val.key.toUpperCase() == selectedStock.country.toUpperCase())
        if (selectedStock !== '' && selectedStock.forexSymbol !== undefined) {

            let symbol = CountryCurrencyList.filter((val) => val.code == selectedStockCountry[0]?.value)
            setCurrencyCodes({ ...currencyCodes, stockCountryCode: selectedStockCountry[0]?.value, stockCountrySymbol: symbol[0]?.symbol_native })

            try {
                // GetExchangeRateTrade(selectedStock.forexSymbol).then((respons) => {
                    GetRapidExchangeRateTrade(selectedStock.forexSymbol).then(
                      (respons) => {
                        if (respons?.status === 200) {
                          // country
                          debugger;

                          let aus = selectedStock.forexSymbol.split("/");
                          let escParam = {
                            CurrencyFrom: aus[1],
                            CurrencyTo: portfolioCountryCode,
                          };
                          try {
                            // GetExchangeRate(escParam).then((res) => {
                            GetRapidExchangeRate(escParam).then((res) => {
                              debugger;
                              const exPrice = Number(
                                res.data?.rates?.[escParam?.CurrencyTo]
                              );

                              const bidPrice = Number(
                                respons.data?.rates?.[
                                  selectedStock.forexSymbol.split("/")[1]
                                ]
                              );
                              const askPrice = Number(
                                respons.data?.rates?.[
                                  selectedStock.forexSymbol.split("/")[1]
                                ]
                              );
                              if (
                                exPrice > 0 &&
                                bidPrice > 0 &&
                                askPrice > 0 &&
                                exPrice != undefined &&
                                bidPrice != undefined &&
                                askPrice != undefined &&
                                exPrice != null &&
                                bidPrice != null &&
                                askPrice != null
                              ) {
                                setExchangerate(exPrice);
                                let data = res.data[0];
                                const twoDecimal = (value) => {
                                  var roundedNumber = value;
                                  var decimalIndex = roundedNumber.indexOf(".");
                                  if (decimalIndex !== -1) {
                                    var decimalPlaces =
                                      roundedNumber.length - decimalIndex - 1;
                                    if (decimalPlaces > 5) {
                                      roundedNumber = roundedNumber.slice(
                                        0,
                                        decimalIndex + 6
                                      );
                                    }
                                  }
                                  return roundedNumber;
                                };
                                let roundedAsk = twoDecimal(
                                  askPrice.toString()
                                );
                                let roundedBid = twoDecimal(
                                  bidPrice.toString()
                                );
                                setPrefillValue({
                                  ...prefillValue,
                                  stockCode: selectedStock.forexSymbol,
                                  currentPrice: Number(roundedBid),
                                  ask_price: Number(roundedAsk),
                                  ask_size: 0,
                                  bid_price: Number(roundedBid),
                                  bid_size: 0,
                                });
                                setAllData({
                                  ...allData,
                                  stockName: selectedStock.forexSymbol,
                                  country: selectedStock?.country,
                                  entry: Number(roundedAsk),
                                  margin:
                                    selectedStock.margin == ""
                                      ? 0
                                      : selectedStock.margin,
                                  stopLoss: "",
                                  target: "",
                                  erew: "",
                                  rewardPercent: 0,
                                });
                                setErew("");
                                setQuantity("");
                              } else {
                                toast.error("Please select valid forex code.");
                              }
                            });
                          } catch (error) {
                            toast.error("Please select valid forex code.");
                          }
                        } else {
                          showToast("Please select valid forex.");
                          setAllData({ ...allData, entry: "", margin: "" });
                          setStockListPaging({
                            ...stockListPaging,
                            search: "",
                          });
                        }
                      }
                    );
            } catch (error) {
            }
        }
        setLoadStockData(false)
    }

    // useEffect(() => {
    //     
    //     getSearchStockData(selectedStock)
    // }, [selectedStock.stockCode])

    // input long/short
    const handleInputRedio = (e) => {
        setError({ target: true, stopLoss: true, stockName: true })
        if (e.target.name === 'long') {
            setAllData({ ...allData, entry: prefillValue.ask_price, stopLoss: '', target: '', reward: '', rewardPercent: 0 })
            setLong('buy')
        } if (e.target.name === 'short') {
            setAllData({ ...allData, entry: prefillValue.bid_price, stopLoss: '', target: '', reward: '', rewardPercent: 0 })
            setLong('sell')
        }
    }

    // Handle price target
    const handleInputtarget = (e) => {

        if (Number(e.target.value) >= 0) {
            if (portFolioId == '') {
                showToast('Select portfolio first.')
            } else if (providerId == '') {
                showToast('Select provider first.')
            } else if (stockCode.length <= 0) {
                showToast('Select forex code first.')
            } else {
                if (entry !== '' && Number(entry) != 0) {
                    if (long === 'sell') {

                        if (entry <= Number(e.target.value)) {
                            showToast('Set lower target price than entry while sell trading')
                            // setAllData({ ...allData, target: '' })
                        } else {
                            handleInput(e)
                        }
                    } else {
                        handleInput(e)
                    }
                } else {
                    showToast('Enter entry price first.')
                }
            }
        }
        // handleInput(e)
    }
    // calculation
    const calculateData = () => {
        // debugger
        if (risk !== '' && entry !== '' && stopLoss !== '') {
            let iqt = 0, shr = 0, rewPre = 0, a = 0
            // 
            if (risk != '' && risk > 0) {
                if (long === 'buy') {
                    iqt = risk / (entry * exchangeRate - stopLoss * exchangeRate)
                    setQuantity(Math.floor(iqt))
                } else {
                    if (Number(entry) < Number(stopLoss)) {
                        iqt = risk / (stopLoss * exchangeRate - entry * exchangeRate)
                        // let sq=JSON.stringify(iqt)
                        setQuantity(Math.floor(iqt))
                    }
                    if (Number(entry) > Number(stopLoss) || stopLoss === '') {
                        iqt = risk / (entry * exchangeRate)
                        setQuantity(Math.floor(iqt))
                    }
                }
            } else {
                setQuantity(Math.floor(iqt))
            }

            if (target > 0 && target !== '') {

                if (long === 'buy') {
                    if (Number(target) > Number(entry)) {
                        a = (target * exchangeRate - entry * exchangeRate) * iqt
                    }
                } else {
                    if (Number(entry) > Number(target)) {
                        a = (entry * exchangeRate - target * exchangeRate) * iqt
                    }

                }

                shr = quantity * (entry * exchangeRate)
                rewPre = (a / shr) * 100
                let cm = 0
                for (let i = 0; i < providerCummission.length; i++) {

                    if (providerCummission[i].upToTransValue >= shr) {
                        if (providerCummission[i].type == "Percent") {
                            cm = (shr * providerCummission[i].commission) / 100
                        } else {
                            cm = providerCummission[i].commission
                        }
                        break;
                    } else {
                        if (providerCummission[i].type == "Percent") {
                            cm = (shr * providerCummission[i].commission) / 100
                        } else {
                            cm = providerCummission[i].commission
                        }
                        break;
                    }
                }
                let cfdVal = (shr * (margin * exchangeRate)) / 100
                setErew(a)
                setAllData({ ...allData, cummission: cm, share: shr, cfd: Number(cfdVal).toFixed(2), rewardPercent: rewPre, reward: a })
            }
        }
        else {
            setQuantity('')
        }
        // if (stopLoss==='') {
        // setAllData({ ...allData, quantity: '' })
        // }
    }
    const handleStopLossInput = (e) => {
        if ((Number(e.target.value) >= 0 && e.target.value != '') || e.target.value == '.') {
            if (e.target.value[0] == 0) {
                setStopLossTableData({ ...stopLossTableData, [e.target.name]: e.target.value[1] })
            } else {
                let modifiedString = twoDecimal(e.target.value)
                let n = e.target.value.split('.')
                if (n.length == 2) {
                    if (n[0].length <= 10) {
                        setStopLossTableData({ ...stopLossTableData, [e.target.name]: modifiedString })
                    }
                } else if (e.target.value.length <= 10) {
                    setStopLossTableData({ ...stopLossTableData, [e.target.name]: modifiedString })
                }
            }
        } else if (e.target.value == '') {
            setStopLossTableData({ ...stopLossTableData, [e.target.name]: '' })
        }
    }
    const stopLossTable = () => {
        //calculation for first row of stop loss grid
        let exChangeEntery = entry * exchangeRate
        let r2c1 = 0, r2c2 = 0, r2c3 = 0, r2c4 = 0, r2c5 = 0, r4c1 = 0, r4c2 = 0, r4c3 = 0, r4c4 = 0, r4c5 = 0, r3c1 = 0, r3c2 = 0, r3c3 = 0, r3c4 = 0, r3c5 = 0,
            r5c1 = 0, r5c2 = 0, r5c3 = 0, r5c4 = 0, r5c5 = 0
        if (dValueATR !== '' && dValueATR !== null) {
            setStopLossTableData({ ...stopLossTableData, dValueATR: parseFloat(dValueATR) })
        }
        if (long === 'buy') {

            r2c1 = Number(exChangeEntery) - dValueATR
            r2c2 = Number(exChangeEntery) - dValue2ATR
            r2c3 = (Number(exChangeEntery) - ((Number(exChangeEntery) * dValuePerCent) / 100));
            r2c4 = Number(exChangeEntery) - dValueDollar
            r2c5 = (dStopLossPrices == undefined || dStopLossPrices == '') ? r2c4 : dStopLossPrices
            r4c1 = (Math.abs(((r2c1 - Number(exChangeEntery)) * quantity) - cummission) + Number.EPSILON)
            r4c2 = (Math.abs(((r2c2 - Number(exChangeEntery)) * quantity) - cummission) + Number.EPSILON);
            r4c3 = (Math.abs((((r2c3 - Number(exChangeEntery)) * quantity) - cummission)) + Number.EPSILON);
            r4c4 = (Math.abs(((r2c4 - Number(exChangeEntery)) * quantity) - cummission) + Number.EPSILON);
            r4c5 = (Math.abs(((r2c5 - Number(exChangeEntery)) * quantity) - cummission) + Number.EPSILON);

        } else {
            r2c1 = Number(exChangeEntery) + dValueATR
            r2c2 = Number(exChangeEntery) - dValue2ATR
            r2c3 = (Number(exChangeEntery) - ((Number(exChangeEntery) * dValuePerCent) / 100));
            r2c4 = Number(exChangeEntery) - dValueDollar
            r2c5 = (dStopLossPrices == undefined || dStopLossPrices == '') ? r2c4 : dStopLossPrices
            r4c1 = (Math.abs(((r2c1 - Number(exChangeEntery)) * quantity) - cummission) + Number.EPSILON)
            r4c2 = (Math.abs(((r2c2 - Number(exChangeEntery)) * quantity) - cummission) + Number.EPSILON);
            r4c3 = (Math.abs((((r2c3 - Number(exChangeEntery)) * quantity) - cummission)) + Number.EPSILON);
            r4c4 = (Math.abs(((r2c4 - Number(exChangeEntery)) * quantity) - cummission) + Number.EPSILON);
            r4c5 = (Math.abs(((r2c5 - Number(exChangeEntery)) * quantity) - cummission) + Number.EPSILON);
        }
        let currentbal = balanceDetails?.currentBalance || 0
        if (currentbal != 0 && currentbal != undefined) {
            r3c1 = (r4c1 / currentbal) * 100
            r3c2 = (r4c2 / currentbal) * 100;
            r3c3 = (r4c3 / currentbal) * 100;
            r3c4 = (r4c4 / currentbal) * 100;
            r3c5 = (r4c5 / currentbal) * 100;
        }
        if (r3c1 != '') {
            r5c1 = (rewardPercent / r3c1)
        }
        if (r3c2 != '') {
            r5c2 = (rewardPercent / r3c2)
        }
        if (r3c3 != '') {
            r5c3 = (rewardPercent / r3c3)
        }
        if (r3c4 != '') {
            r5c4 = (rewardPercent / r3c4)
        }
        if (r3c5 != '') {
            r5c5 = (rewardPercent / r3c5)
        }
        setStopLossTableData({ ...stopLossTableData, dRRRPrice: r5c5, dRRRDollar: r5c4, dRRRPerCent: r5c3, dRRR2ATR: r5c2, dRRRATR: r5c1, dRiskPerPrice: r3c5, dRiskPerDollar: r3c4, dRiskPerPerCent: r3c3, dRiskPer2ATR: r3c2, dRiskPerATR: r3c1, dRiskDol2ATR: r4c2, dRiskDolPerCent: r4c3, dRiskDolDollar: r4c4, dRiskDolPrice: r4c5, dRiskDolATR: r4c1, dStopLossDollar: r2c4, dStopLossPerCent: r2c3, dStopLoss2ATR: r2c2, dStopLossATR: r2c1, dValue2ATR: dValueATR * 2 })
    }
    useEffect(() => {
        if (quantity !== 0 && quantity != '' && quantity !== undefined && Number(entry) > 0) {
            stopLossTable()
        }
    }, [dValueATR, dValue2ATR, entry, quantity, dStopLossPrices, dValuePerCent, rewardPercent, dValueDollar, exchangeRate])


    useEffect(() => {
        if (target > 0 && stopLoss > 0 && entry > 0) {
            calculateData()
        }
    }, [stopLoss, risk, entry, long, target, quantity, erew, exchangeRate])


    const getbalanceTrans = () => {

        if (portFolioId !== '' && portFolioId !== undefined) {
            let data = {
                userId: userType?.userId,
                portfolioId: portFolioId
            }
            if (data.userId !== undefined && portFolioId !== undefined) {
                try {
                    GetCurrentBalabce(data).then((res) => {
                        if (res?.status === 200) {
                            setBalanceDetails(res.data)

                        }
                    })
                } catch (error) {

                }
            }
            // setBalanceDetails(currentBalanceFun())
            let paramData = {
                portfolioId: portFolioId,
                pageNumber: 1,
                pageSize: 500,
                id: userType?.userId,
            }

            if (paramData.id !== undefined && paramData.portfolioId !== undefined) {
                try {
                    getOpentradeList(paramData).then((res) => {
                        if (res?.status === 200 && res.data.data.length > 0) {
                            let data = res.data.data
                            let pft = 0
                            let bs = 0
                            let bcfd = 0, lev



                            for (let i = 0; i < data.length; i++) {
                                let qty = Number(data[i].quantity)
                                let transp = Number(data[i].valueInShares)
                                let filteredCountry = portfololioList.filter((value) => value.portfolioId == data[i].portfolioId)
                                let stockCodeArr = data[i].stockCode.split('/')
                                try {
                                    // GetExchangeRateTrade(data[i].stockCode).then((respons) => {
                                        GetRapidExchangeRateTrade(
                                          data[i].stockCode
                                        ).then((respons) => {
                                          if (res?.status === 200) {
                                            let exParam = {
                                              CurrencyFrom: stockCodeArr[1],
                                              CurrencyTo:
                                                filteredCountry[0]?.currency,
                                            };
                                            try {
                                              // GetExchangeRate(exParam).then((res) => {
                                              GetRapidExchangeRate(
                                                exParam
                                              ).then((res) => {
                                                const exPrice =
                                                  Number(
                                                    res?.data?.rates?.[
                                                      exParam?.CurrencyTo
                                                    ]
                                                  ) || 0;
                                                const bidPrice =
                                                  Number(
                                                    respons?.data?.rates?.[
                                                      data[i].stockCode.split(
                                                        "/"
                                                      )[1]
                                                    ]
                                                  ) || 0;
                                                const askPrice =
                                                  Number(
                                                    respons?.data?.rates?.[
                                                      data[i].stockCode.split(
                                                        "/"
                                                      )[1]
                                                    ]
                                                  ) || 0;
                                                // let exRate = respons.data.rate

                                                if (data[i]?.short == "buy") {
                                                  let cls = askPrice * exPrice;
                                                  pft =
                                                    pft + cls * qty - transp;
                                                } else {
                                                  let cls = bidPrice * exPrice;
                                                  pft =
                                                    transp - (pft + cls * qty);
                                                }
                                                const twoDecimal = (value) => {
                                                  var roundedNumber = value;
                                                  var decimalIndex =
                                                    roundedNumber.indexOf(".");
                                                  if (decimalIndex !== -1) {
                                                    var decimalPlaces =
                                                      roundedNumber.length -
                                                      decimalIndex -
                                                      1;
                                                    if (decimalPlaces > 2) {
                                                      roundedNumber =
                                                        roundedNumber.slice(
                                                          0,
                                                          decimalIndex + 3
                                                        );
                                                    }
                                                  }
                                                  return roundedNumber;
                                                };
                                                let roundedPft = twoDecimal(
                                                  pft.toString()
                                                );
                                                setProfit(Number(roundedPft));
                                              });
                                            } catch (error) {}

                                            // setAccountData({ ...accountData, profit: pft.toFixed(2)})
                                          }
                                        });
                                } catch (error) {

                                }
                                bs = bs + Number(data[i].valueInShares)
                                bcfd = bcfd + data[i].valueInCFD
                            }


                            lev = (bcfd / bs) * 100
                            const twoDecimal = (value) => {

                                var roundedNumber = value;
                                var decimalIndex = roundedNumber.indexOf(".");
                                if (decimalIndex !== -1) {
                                    var decimalPlaces = roundedNumber.length - decimalIndex - 1;
                                    if (decimalPlaces > 2) {
                                        roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
                                    }
                                }
                                return roundedNumber
                            }
                            let roundedBS = twoDecimal(bs.toString())
                            let roundedBCFD = twoDecimal(bcfd.toString())
                            let roundedLEV = twoDecimal(lev.toString())
                            setAccountData({ ...accountData, balanceinShares: Number(roundedBS), balanceinCFD: Number(roundedBCFD), leverage: Number(roundedLEV) })
                        } else {
                            setProfit(0)
                            setAccountData({ ...accountData, balanceinShares: 0, balanceinCFD: 0, leverage: 0 })
                        }
                    })
                } catch (error) {

                }
            }
        }
    }
    useEffect(() => {
        getbalanceTrans()
    }, [portFolioId, userType?.userId, providerId])


    const handleCalculatedData = () => {

        let vs = allData.entry * exchangeRate * quantity
        if (balanceDetails?.currentBalance > vs) {
            if (Number(entry) > 0 && Number(stopLoss) > 0 && Number(target) > 0 && Number(risk) > 0) {
                // setOpenTradeRun(true)
                setStockListPaging({ ...stockListPaging, search: '' })
                setCalculatedData({ ...allData, quantity, ...selectedStock, short: long, ...prefillValue })
                handleRemoveCalculatedData()
                document.getElementById('open-trade-btn').click()
            } else {
                showToast('Values must be greater then zero (0).')
            }
        } else {
            showToast('Does not have sufficient balance!')
        }
    }

    const handleRemoveCalculatedData = () => {
        // if (calculatedData != undefined && calculateData != '') {
        setPrefillValue({ ...prefillValue, stockCode: '', stockName: '', currentPrice: '' })
        setError({ target: true, stopLoss: true })
        setStockListPaging({ ...stockListPaging, search: '' })
        setBalanceDetails({
            currentBalance: 0,
            dipositBalanceTill: 0,
            id: 0,
            portfolioId: 0,
            tradeBalanceTill: 0,
            userId: 0,
            withdrawalBalanceTill: 0
        })
        setQuantity('')
        setErew(0)
        setSelectedStockr('')
        setSelectedStock('')
        setStocksList([])
        setShowStockListDropDown(false)
        setAllData({ portFolioId: '', stockName: '', providerName: '', providerId: '', cummission: 0, riskPer: '', entry: '', share: 0, margin: 0, risk: '', cfd: '', stopLoss: '', quantity: 0, target: '', reward: 0, rewardPercent: 0, reRender: allData.reRender + 1 })
        document.querySelectorAll('input').value = 0
        // asd.value=''
        // document.getElementById('myform').reset();
        document.getElementById('closeCalculater').click()
        // }
        // let clsc = document.getElementById('closeCalculater')
        // clsc.click()
    }

    const handleSelectInputStock = (e) => {
        // 
        if (portFolioId == '') {
            showToast('Select portfolio first.')
        } else if (providerId == '') {
            showToast('Select provider first.')
        } else {
            let n = e.target.value
            setPrefillValue({ ...prefillValue, stockCode: n })
            setAllData({ ...allData, stockName: e.target.value })
            setStockListPaging({ ...stockListPaging, search: n })
            // setAddStockData({ ...addStockData, stockCode: e.target.value })
            setShowStockListDropDown(true)
        }
    }

    const handleSearchSelectStockDetails = (val) => {
        setLoadStockData(true)
        getSearchStockData(val)
        setSelectedStock(val)
        setSelectedStockr(val)
        setStockListPaging({ ...stockListPaging, search: val.stockCode })
        setShowStockListDropDown(false)
    }



    var selectElement = document.getElementById('selectScroll');
    var page = 1; // Track the page number for pagination
    var isLoading = false; // Track if data is currently being loaded
    // Event handler for scroll event on select element
    selectElement?.addEventListener('scroll', () => {
        // 
        if (selectElement.scrollTop + selectElement.clientHeight >= selectElement.scrollHeight) {
            // User has scrolled to the bottom
            // Load more data if not already loading
            if (!isLoading) {
                loadData()
            }
        }
    });

    // Function to load additional data from the API
    function loadData() {
        isLoading = true; // Set loading state
        setStockListPaging({ ...stockListPaging, PageNumber: 1, PageSize: 100 + PageSize })
    }

    let validationSchema = Yup.object().shape({
        portFolioId: Yup.string().required('Portfolio is required'),
        providerId: Yup.string().required('Provider is required'),
        stopLoss: Yup.string().required('Stop loss is required').typeError('Stoploss must be a number.'),
        risk: Yup.string().required('Risk is required'),
        riskPer: Yup.string().required('Risk (%) is required'),
        target: Yup.string().required('Price Target is required').typeError('Target must be a number.'),
        entry: Yup.string().required('Entry Price is required').typeError('Entry must be a number.'),
        stockName: Yup.string().required('Forex code is required')
    });

    const handleCheckValidation = (e) => {
        if (Number(e.target.value) >= 0 && entry != '' && entry != 0) {
            if (entry !== '') {
                if (e.target.name == 'target') {
                    if (long === 'buy') {
                        if (Number(entry) > Number(e.target.value)) {
                            showToast('Set higher target price than entry while buy trading')
                            setError({ ...error, target: false })
                        } else {
                            setError({ ...error, target: true })
                        }
                    } else {
                        setError({ ...error, target: true })
                    }
                } else {
                    if (long === 'sell') {
                        if (Number(entry) >= Number(e.target.value)) {
                            showToast('Set higher stop loss price than entry while sell trading')
                            setError({ ...error, stopLoss: false })
                        } else {
                            setError({ ...error, stopLoss: true })
                        }
                    } else {
                        setError({ ...error, stopLoss: true })
                    }
                }
            }
        }
    }
    const hideOnBlur = () => {

    }
    return (
        <>
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="modal fade backdrop-1" data-bs-backdrop="static" id="calculator" aria-labelledby="exampleModalLgLabel"
                            aria-modal="true" role="dialog" style={{ "display": "none;" }}>
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content cal-model">
                                    <div class="modal-header">
                                        <h5 class="modal-title h4" id="exampleModalLgLabel">Calculator</h5>
                                        <button type="button" class="btn-close btn-close-provider" data-bs-dismiss="modal" id="closeCalculater"
                                            aria-label="Close" onClick={handleRemoveCalculatedData}>×</button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="postion-bx-min mt-2 ">
                                                    <span class="frm-hd-t">Position Sizing</span>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12 mt-1">
                                                        <div class="postion-min-dta">
                                                            <Formik
                                                                initialValues={allData}
                                                                enableReinitialize={true}
                                                                validationSchema={validationSchema}
                                                                onSubmit={(value) => {
                                                                    if (error.target == true && error.stopLoss == true) {
                                                                        handleCalculatedData()
                                                                    }
                                                                }}
                                                            >
                                                                {({ errors, touched, values }) => (
                                                                    <Form>
                                                                        <div class="row">
                                                                            <div class="col-sm-6">
                                                                                <div class="calu-bx-modl mt-1">
                                                                                    <div class="mb-3">
                                                                                        <label for="formGroupExampleInput"
                                                                                            class="form-label">Portfolio <span style={{ "color": "#f00" }}>*</span></label>
                                                                                        <select class="form-select" value={portFolioId} onChange={handleInput} onClick={() => setShowStockListDropDown(false)} onBlur={hideOnBlur} name='portFolioId' type="select"
                                                                                            id="autoSizingSelectBroker">
                                                                                            <option selected="" value=''>---Select---
                                                                                            </option>
                                                                                            {
                                                                                                brokerList?.map((val, ind) => {
                                                                                                    return (<option key={ind} value={val.portfolioId}>{val.portfolioName}</option>)
                                                                                                })
                                                                                            }
                                                                                        </select>
                                                                                        {errors.portFolioId && touched.portFolioId ? (
                                                                                            <div className='color-msg-err'>{errors.portFolioId}</div>
                                                                                        ) : null}
                                                                                        <label for="formGroupExampleInput"
                                                                                            class="form-label">Provider <span style={{ "color": "#f00" }}>*</span></label>
                                                                                        <select class="form-select" value={providerId} onChange={handleInput} onBlur={hideOnBlur} onClick={() => setShowStockListDropDown(false)} name='providerId' type="select"
                                                                                            id="autoSizingSelectBroker">
                                                                                            <option selected="" value=''>---Select--- <span style={{ "color": "#f00" }}>*</span>
                                                                                            </option>
                                                                                            {
                                                                                                providerList?.map((val, ind) => {
                                                                                                    return (<option key={ind} value={val.providerId}>{val.providerName}</option>)
                                                                                                })
                                                                                            }
                                                                                        </select>
                                                                                        {errors.providerId && touched.providerId ? (
                                                                                            <div className='color-msg-err'>{errors.providerId}</div>
                                                                                        ) : null}
                                                                                        <div className='position-relative'>
                                                                                            <label for="formGroupExampleInput"
                                                                                                class="form-label" >Forex <span style={{ "color": "#f00" }}>*</span></label>
                                                                                            <Field autocomplete={'off'} name='stockName' onBlur={hideOnBlur} class="form-control" id='selectScrollInput' value={stockName} onClick={() => setShowStockListDropDown(!showStockListDropDown)} placeholder='eg. EUR/USD' onChange={handleSelectInputStock} />
                                                                                            {errors.stockName && touched.stockName ? (
                                                                                                <div className='color-msg-err'>{errors.stockName}</div>
                                                                                            ) : null}
                                                                                            {(showStockListDropDown && portFolioId != '') &&
                                                                                                <div name='stockName' className='dropdown-search stockOption_cal stockOption' id="selectScroll" >
                                                                                                    {
                                                                                                        (stocksList.length > 0 && !loadStockData) ?
                                                                                                            stocksList.map((val, ind) => {

                                                                                                                {/* if(stockArr[stockArr.length-1]=='USD'){ */ }

                                                                                                                return (
                                                                                                                    <option key={ind}
                                                                                                                        onClick={() => handleSearchSelectStockDetails(val)}
                                                                                                                    >{val.forexSymbol}</option>
                                                                                                                )
                                                                                                                {/* } */ }
                                                                                                            })
                                                                                                            : <div class="w-100 text-center"><h4 class="no-data">No data found</h4></div>
                                                                                                    }
                                                                                                </div>
                                                                                            }
                                                                                        </div>

                                                                                        {/* <select class="form-select" onChange={handleInput} value={selectedStockr} name='stock' type="select"
                                                                                        id="autoSizingSelect"
                                                                                        required="required">
                                                                                        <option selected="">---Select---
                                                                                        </option>
                                                                                        {
                                                                                            stocksList.map((val, ind) => <option key={ind} value={JSON.stringify(val)}>{val.stockCode}</option>)
                                                                                        }
                                                                                    </select> */}
                                                                                        {/* <AsyncLoadingSelect stocksList={stocksList} setSelectedStock={setSelectedStock} /> */}

                                                                                        <label for="formGroupExampleInput"
                                                                                            class="form-label">Entry
                                                                                            Price {stockCountrySymbol} <span style={{ "color": "#f00" }}>*</span></label>
                                                                                        <Field type="text" class="form-control" value={entry} onClick={() => setShowStockListDropDown(false)} name='entry' onChange={handleInput} onBlur={hideOnBlur}
                                                                                            id="autoSizingInputEntry"
                                                                                            placeholder="0" />
                                                                                        {errors.entry && touched.entry ? (
                                                                                            <div className='color-msg-err'>{errors.entry}</div>
                                                                                        ) : null}
                                                                                        <div class="row mt-4 radio-input">
                                                                                            <label for="inputEmail3"
                                                                                                class="col-sm-4 col-form-label ms-2">Buy
                                                                                                / Sell</label>
                                                                                            <div class="col-sm-7 ms-auto">
                                                                                                <input class="form-check-input" onClick={handleInputRedio} checked={long == 'buy'} name="long"
                                                                                                    type="radio"
                                                                                                    id="gridRadios1"
                                                                                                />
                                                                                                <label class="form-check-label "
                                                                                                    for="gridRadios1">
                                                                                                    Buy
                                                                                                </label>

                                                                                                <input class="form-check-input" onClick={handleInputRedio} checked={long == 'buy' ? false : true} name="short"
                                                                                                    type="radio"
                                                                                                    id="gridRadios2"
                                                                                                />
                                                                                                <label class="form-check-label "
                                                                                                    for="gridRadios2">
                                                                                                    Sell
                                                                                                </label>

                                                                                            </div>

                                                                                        </div>
                                                                                        <label for="formGroupExampleInput"
                                                                                            class="form-label mt-3">Price
                                                                                            Target {stockCountrySymbol} <span style={{ "color": "#f00" }}>*</span></label>
                                                                                        <Field type="text" class={error.target == false ? "input-err-color form-control" : "form-control"} onClick={() => setShowStockListDropDown(false)} name='target' value={target} onBlur={handleCheckValidation} onChange={handleInputtarget}
                                                                                            id="autoSizingInputPriceTarget"
                                                                                            placeholder="0" />
                                                                                        {errors.target && touched.target ? (
                                                                                            <div className='color-msg-err'>{errors.target}</div>
                                                                                        ) : null}

                                                                                        <button type="button" data-bs-toggle="modal" data-bs-target="#cummissionDetails"
                                                                                            class="btn commi-btn-link text-end mt-3 mb-2">Commission Details?</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-sm-6 ">

                                                                                <div class="col-sm-12 ">
                                                                                    <label for="inputEmail3"
                                                                                        class="form-label">Risk % <span style={{ "color": "#f00" }}>*</span></label>
                                                                                    <Field type="text" class="form-control" onClick={() => setShowStockListDropDown(false)} name='riskPer' onBlur={hideOnBlur} onChange={handleInput} value={riskPer}
                                                                                        id="autoSizingInputRisk"
                                                                                        placeholder="0" />

                                                                                    {errors.riskPer && touched.riskPer ? (
                                                                                        <div className='color-msg-err'>{errors.riskPer}</div>
                                                                                    ) : null}
                                                                                </div>

                                                                                <div class="col-sm-12">
                                                                                    <label for="inputEmail3"
                                                                                        class="form-label">Risk {portfolioCurrency} <span style={{ "color": "#f00" }}>*</span></label>
                                                                                    <Field type="text" class="form-control" onClick={() => setShowStockListDropDown(false)} name='risk' onBlur={hideOnBlur} onChange={handleInput} value={risk}
                                                                                        id="autoSizingInputRisk"
                                                                                        placeholder="0" />
                                                                                    {errors.risk && touched.risk ? (
                                                                                        <div className='color-msg-err'>{errors.risk}</div>
                                                                                    ) : null}
                                                                                </div>

                                                                                <div class="col-sm-12 ">
                                                                                    <label for="inputEmail3" class="form-label">Stop
                                                                                        Loss {stockCountrySymbol} <span style={{ "color": "#f00" }}>*</span></label>
                                                                                    <Field type="text" onClick={() => setShowStockListDropDown(false)} class={error.stopLoss == false ? "input-err-color form-control" : "form-control"} name='stopLoss' onBlur={handleCheckValidation} value={stopLoss} onChange={handleInput}
                                                                                        id="autoSizingInputstopLoss"
                                                                                        placeholder="0" />
                                                                                    {errors.stopLoss && touched.stopLoss ? (
                                                                                        <div className='color-msg-err'>{errors.stopLoss}</div>
                                                                                    ) : null}
                                                                                </div>

                                                                                <div class="col-sm-12 ">
                                                                                    <label for="inputEmail3"
                                                                                        class="form-label">Quantity </label>
                                                                                    <input type="text" class="form-control" disabled name='quantity' value={quantity}
                                                                                        id="autoSizingInputquantity"
                                                                                        placeholder="0" />
                                                                                </div>

                                                                                <div class="col-sm-12 ">
                                                                                    <label for="inputEmail3"
                                                                                        class="form-label">Reward {portfolioCurrency}</label>
                                                                                    <input type="text" class="form-control" disabled name='reward' value={Number(erew || 0)?.toFixed(2) || 0}
                                                                                        id="autoSizingInputreward"
                                                                                        placeholder="0" />
                                                                                </div>

                                                                                <div class="col-sm-12 ">
                                                                                    <label for="inputEmail3"
                                                                                        class="form-label">Reward %</label>
                                                                                    <input type="text" class="form-control" disabled name='rewardPercent' value={twoDecimalPoint(rewardPercent || 0) || 0 + ' %'}
                                                                                        id="autoSizingInputrewardPercent"
                                                                                        placeholder="0" />
                                                                                </div>


                                                                                <div className='position-relative'>
                                                                                    <button type="submit"
                                                                                        class="btn commi-btn mt-4 mb-2 ms-auto d-block text-white">Trade
                                                                                        this Trade</button>
                                                                                    <button type="button" data-bs-toggle="modal" id='open-trade-btn' data-bs-target="#openTrade"
                                                                                        class="btn open-trade-btn"></button>
                                                                                </div>

                                                                            </div>


                                                                        </div>
                                                                    </Form>
                                                                )}
                                                            </Formik>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div class="row mt-2"> */}
                                            <div class="col-sm-6 ">
                                                <div class="postion-bx-min mt-4">

                                                    <span class="frm-hd-t">Forex Summary</span>

                                                    <div class="postion-frm mt-1">

                                                        <form>


                                                            <div class="col-sm-12 mb-2">
                                                                <label for="inputEmail3" class="form-label">Forex Name</label>
                                                                <input type="text" disabled class="form-control" value={stockName}
                                                                    id="autoSizingInputstockName" placeholder="0" />
                                                            </div>

                                                            <div class="col-sm-12 mb-2">
                                                                <label for="inputEmail3" class="form-label">Margin %</label>
                                                                <input type="text" class="form-control" name='margin' value={margin} onBlur={hideOnBlur} onChange={handleInput}
                                                                    id="autoSizingInputmargin" placeholder="0" />
                                                            </div>
                                                            <div class="col-sm-12 mb-2">
                                                                <label for="inputEmail3" class="form-label">Margin</label>
                                                                <input type="text" class="form-control" name='cfd' value={cfd} onBlur={hideOnBlur} onChange={handleInput}
                                                                    id="autoSizingInputcfd" placeholder="0" />
                                                            </div>
                                                            <div class="col-sm-12 mb-2">
                                                                <label for="inputEmail3" class="form-label" >Current
                                                                    Price {portfolioCurrency}</label>
                                                                <input type="text" disabled class="form-control" value={currentPrice > 0 ? Number(currentPrice * exchangeRate).toFixed(2) : currentPrice}
                                                                    id="autoSizingInputcurrentPrice" placeholder="" />
                                                            </div>

                                                            <div class="col-sm-12 mb-2">
                                                                <label for="inputEmail3"
                                                                    class="form-label">Commissions</label>
                                                                <input type="text" disabled class="form-control" value={cummission?.toFixed(2)}
                                                                    id="autoSizingInputcummission" placeholder="" />
                                                            </div>

                                                            <div class="col-sm-12 mb-2">
                                                                <label for="inputEmail3" class="form-label">Forex {portfolioCurrency}</label>
                                                                <input type="text" disabled class="form-control" value={share?.toFixed(2)}
                                                                    id="autoSizingInputshare" placeholder="" />
                                                            </div>
                                                        </form>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6 ">
                                                <div class="postion-bx-min mt-4">

                                                    <span class="frm-hd-t">Account Summary</span>

                                                    <div class="postion-frm mt-1">

                                                        <form>


                                                            <div class="col-sm-12 mb-2">
                                                                <label for="inputEmail3" id='inputEmail3' class="form-label">Current
                                                                    Balance {portfolioCurrency}</label>
                                                                <input type="text" disabled class="form-control" value={Number(balanceDetails?.currentBalance).toFixed(2)}
                                                                    id="autoSizingInputbalanceDetails" placeholder="" />
                                                            </div>

                                                            <div class="col-sm-12 mb-2">
                                                                <label for="inputEmail3" class="form-label">Balance In Margin {portfolioCurrency}
                                                                </label>
                                                                <input type="text" disabled class="form-control" value={accountData?.balanceinCFD}
                                                                    id="autoSizingInputaccountData" placeholder="" />
                                                            </div>

                                                            <div class="col-sm-12 mb-2">
                                                                <label for="inputEmail3" class="form-label">Balance
                                                                    Cash {portfolioCurrency}</label>
                                                                <input type="text" disabled class="form-control" value={(balanceDetails?.currentBalance - accountData?.balanceinCFD)}
                                                                    id="autoSizingInputbalanceDetails" placeholder="" />
                                                            </div>

                                                            <div class="col-sm-12 mb-2">
                                                                <label for="inputEmail3" class="form-label">Profit/
                                                                    Loss {portfolioCurrency}</label>
                                                                <input type="text" disabled class="form-control" value={profit}
                                                                    id="autoSizingInputprofit" placeholder="" />
                                                            </div>

                                                            <div class="col-sm-12 mb-2">
                                                                <label for="inputEmail3" class="form-label">Balance in
                                                                    Forex {portfolioCurrency}</label>
                                                                <input type="text" disabled value={accountData.balanceinShares} class="form-control"
                                                                    id="autoSizingInputbalanceinShares" placeholder="" />
                                                            </div>



                                                        </form>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-12 mt-2 mb-4">

                                                <div class="postion-bx-min mt-4">
                                                    <span class="frm-hd-t frm-hd-t1">StopLoss</span>
                                                </div>



                                                <div class="table-input">

                                                    <table class="table table-bordered mt-2 table-input-data">

                                                        <thead>
                                                            <tr>
                                                                <th scope="col" class="text-center color-grey">Stop Loss</th>
                                                                <th scope="col" class="text-center color-grey">ATR</th>
                                                                <th scope="col" class="text-center color-grey">2ATR</th>
                                                                <th scope="col" class="text-center color-grey">% <span><i
                                                                    class="bi bi-caret-down-fill"></i></span>
                                                                    <span><i class="bi bi-caret-up-fill"></i></span> </th>
                                                                <th scope="col" class="text-center color-grey"> &nbsp; </th>
                                                                <th scope="col" class="text-center color-grey">Price</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr class="border-bottom-0">
                                                                <th class="col-sm-2 white_text">Stop Loss As</th>
                                                                <td class="col-sm-2"><input type="text" class="form-control" name='dValueATR'
                                                                    id="autoSizingInputdValueATR" placeholder="" value={dValueATR} onChange={handleStopLossInput} />
                                                                </td>
                                                                <td class="col-sm-2 border-bottom-0"><input type="text"
                                                                    class="form-control" value={dValue2ATR} disabled
                                                                    id="autoSizingInputdValue2ATR" placeholder="" /></td>
                                                                <td class="col-sm-2"><input type="text" class="form-control" name='dValuePerCent'
                                                                    id="autoSizingInputdValuePerCent" placeholder="" value={dValuePerCent} onChange={handleStopLossInput} />
                                                                </td>
                                                                <td class="col-sm-2"><input type="text" class="form-control" name='dValueDollar'
                                                                    id="autoSizingInputdValueDollar" placeholder="" value={dValueDollar} onChange={handleStopLossInput} />
                                                                </td>
                                                                <td class="col-sm-2"><input type="text" class="form-control"
                                                                    id="autoSizingInputdValuePrice" placeholder="" value={Number(dValuePrice).toFixed(2)} disabled />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th class="col-sm-2 white_text">Stop Loss Price</th>
                                                                <td class="col-sm-2"><input type="text" class="form-control" disabled value={Number(dStopLossATR).toFixed(2)}
                                                                    id="autoSizingInputdStopLossATR" placeholder="" />
                                                                </td>
                                                                <td class="col-sm-2 border-bottom-0"><input type="text" disabled value={Number(dStopLoss2ATR).toFixed(2)}
                                                                    class="form-control"
                                                                    id="autoSizingInputdStopLoss2ATR" placeholder="" /></td>
                                                                <td class="col-sm-2"><input type="text" class="form-control" disabled value={Number(dStopLossPerCent).toFixed(2)}
                                                                    id="autoSizingInputdStopLossPerCent" placeholder="" />
                                                                </td>
                                                                <td class="col-sm-2"><input type="text" class="form-control" disabled value={Number(dStopLossDollar).toFixed(2)}
                                                                    id="autoSizingInputdStopLossDollar" placeholder="" />
                                                                </td>
                                                                <td class="col-sm-2"><input type="text" class="form-control" name='dStopLossPrices' value={dStopLossPrices} onChange={handleStopLossInput}
                                                                    id="autoSizingInputdStopLossPrices" placeholder="" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th className='white_text'>Risk %</th>
                                                                <td class="col-sm-2"><input type="text" class="form-control" disabled value={Number(dRiskPerATR)?.toFixed(2) + " %"}
                                                                    id="autoSizingInputdRiskPerATR" placeholder="" />
                                                                </td>
                                                                <td class="col-sm-2 border-bottom-0"><input type="text" disabled value={Number(dRiskPer2ATR)?.toFixed(2) + " %"}
                                                                    class="form-control"
                                                                    id="autoSizingInputdRiskPer2ATR" placeholder="" /></td>
                                                                <td class="col-sm-2"><input type="text" class="form-control" disabled value={Number(dRiskPerPerCent)?.toFixed(2) + " %"}
                                                                    id="autoSizingInputdRiskPerPerCent" placeholder="" />
                                                                </td>
                                                                <td class="col-sm-2"><input type="text" class="form-control" disabled value={Number(dRiskPerDollar)?.toFixed(2) + " %"}
                                                                    id="autoSizingInputdRiskPerDollar" placeholder="" />
                                                                </td>
                                                                <td class="col-sm-2"><input type="text" class="form-control" disabled value={Number(dRiskPerPrice)?.toFixed(2) + " %"}
                                                                    id="autoSizingInputdRiskPerPrice" placeholder="" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th className='white_text'>Risk</th>
                                                                <td class="col-sm-2"><input type="text" class="form-control" disabled value={" $ " + Number(dRiskDolATR)?.toFixed(2)}
                                                                    id="autoSizingInputdRiskDolATR" placeholder="" />
                                                                </td>
                                                                <td class="col-sm-2 border-bottom-0"><input type="text" disabled value={" $ " + Number(dRiskDol2ATR)?.toFixed(2)}
                                                                    class="form-control"
                                                                    id="autoSizingInputdRiskDol2ATR" placeholder="" /></td>
                                                                <td class="col-sm-2"><input type="text" class="form-control" disabled value={" $ " + Number(dRiskDolPerCent)?.toFixed(2)}
                                                                    id="autoSizingInputdRiskDolPerCent" placeholder="" />
                                                                </td>
                                                                <td class="col-sm-2"><input type="text" class="form-control" disabled value={" $ " + Number(dRiskDolDollar)?.toFixed(2)}
                                                                    id="autoSizingInputdRiskDolDollar" placeholder="" />
                                                                </td>
                                                                <td class="col-sm-2"><input type="text" class="form-control" disabled value={" $ " + Number(dRiskDolPrice)?.toFixed(2)}
                                                                    id="autoSizingInputdRiskDolPrice" placeholder="" />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th className='white_text' >RRR</th>
                                                                <td class="col-sm-2"><input type="text" class="form-control" disabled value={Number(dRRRATR)?.toFixed(2)}
                                                                    id="autoSizingInputdRRRATR" placeholder="" />
                                                                </td>
                                                                <td class="col-sm-2 border-bottom-0"><input type="text" disabled value={Number(dRRR2ATR)?.toFixed(2)}
                                                                    class="form-control"
                                                                    id="autoSizingInputdRRR2ATR" placeholder="" /></td>
                                                                <td class="col-sm-2"><input type="text" class="form-control" disabled value={Number(dRRRPerCent)?.toFixed(2)}
                                                                    id="autoSizingInputdRRRPerCent" placeholder="" />
                                                                </td>
                                                                <td class="col-sm-2"><input type="text" class="form-control" disabled value={Number(dRRRDollar)?.toFixed(2)}
                                                                    id="autoSizingInputdRRRDollar" placeholder="" />
                                                                </td>
                                                                <td class="col-sm-2"><input type="text" class="form-control" disabled value={Number(dRRRPrice)?.toFixed(2)}
                                                                    id="autoSizingInputdRRRPrice" placeholder="" />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>





                                            {/* </div> */}

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <CummissionDetails HandleViewCummission={HandleViewCummission} cummissionData={providerCummission} paginationCommi={paginationCommi} setPaginationCommi={setPaginationCommi} />
            {/* <CreateTrade allData={allData} portfololioList={portfololioList} setRunPortFolioAPI={setRunPortFolioAPI} providerList={providerList} /> */}
        </>
    )
}

export default TradeCalculater